import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import TextField from '@mui/material/TextField';

interface propsT {
  value: string;
  onChange: any;
  label?: string;
  placeHolder?: string;
  activeUpdate?: boolean;
  type?: string;
  width?: number;
  error?: boolean;
  handleBlur?: () => void;
}

const InputText = (props: propsT) => {
  const { value, onChange } = props;
  const [localValue, setLocalValue] = useState<string>(value || '');
  const [localValidation, setLocalValidation] = useState<boolean>(false); // new form have it true unless error
  const textType = props?.type ? props.type : 'text';

  let error = localValidation ? localValue === '' : props.error;
  if (props?.label === 'Email Address' && props.error) error = true;
  function handleChange(e) {
    setLocalValue(e.target.value);
  }

  function handleBlur() {
    props.handleBlur();
    onChange(localValue);
  }

  // once error is thrown, then we know validation happened in upper component
  // now validate at local component
  useEffect(() => {
    if (props.error) {
      if (!localValidation) setLocalValidation(true);
    }
  }, [props.error]);

  return (
    <TextFieldContainer>
      <StyledTextField
          id={props.value + '_' + props?.label}
          key={props.value + '_' + props?.label}
          type={textType}
          label={props?.label}
          value={localValue}
          placeholder={props?.placeHolder ? props.placeHolder : 'Enter value ...'}
          onChange={handleChange}
          onBlur={handleBlur}
          error={error}
          autoComplete='chrome-off'
      />
    </TextFieldContainer>
  );
};

export default InputText;

const TextFieldContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
`;

const StyledTextField = styled(TextField)`
  padding-top: 3px;
  width: 100%;

  & .MuiInputBase-root {
    // match the border radius around the input field
    border-radius: 10px;
    font-size: 14px;
  }

  & .MuiOutlinedInput-input {
    // reduce the padding for more compact form
    // padding: 10px;
    background: transparent;
  }

  & .MuiFormLabel-root {
    // set a smaller label (no input) to adjust to reduced padding
    font-size: 14px;
  }

  & .MuiOutlinedInput-input::placeholder {
    // adjust the placeholder - when field in focus
    padding: 10px;
    font-size: 12px;
    font-style: italic;
  }
  // Handle the autofill color
  & :-webkit-autofill,
  & :-webkit-autofill:hover,
  & :-webkit-autofill:focus,
  & :-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  }
`;
