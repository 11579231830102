import React, { createContext } from 'react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export interface UserT {
  isLoading: boolean;              // make sure that AuthWrapper has time to check for user authentication session and data
  isAuthenticated: boolean;        // first level registration (Cognito)
  isRegistered: boolean;           // user Information -- done at create account and stored in AsedaSciences API (global)
  isAppAuthorized: boolean;        // anything related to the particular app
  authId: string;                  // holds the Cognito ID
  authEmail: string;               // holds the email used with the cognito ID
  authSession: CognitoUserSession, // when authenticated holds all the cognito authentication information
  regInfo: {[key: string]: any};   // holds the user registration retrieved after authentication
  [key: string]: any;              // Additional user fields if necessary
}

interface UserContextProps {
  user: UserT | null;
  setUser: React.Dispatch<React.SetStateAction<UserT | null>>;
}

export const UserEmpty:UserT = {
  isLoading: true,
  isAuthenticated: false,
  isRegistered: false,
  isAppAuthorized: false,
  authId: null,
  authEmail: null,
  authSession: null,
  regInfo: {},
  creditsInfo: { creditsUsed: 0, creditsAvailable: 0 }
}

export const UserContext = createContext<UserContextProps>(undefined);
