import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import { VisibilityRounded, VisibilityOffRounded } from '@mui/icons-material';
import PasswordStrengthBar from './password/PasswordStrengthBar';
import PasswordReason from '@as_core/account/fields/password/PasswordReason';

interface propsI {
  id: string;
  label?: string;
  value: string;
  onChange: any;
  placeHolder?: string;
  activeUpdate?: boolean;
  width?: number;
  error?: boolean;
  handleBlur?: () => void;
}

const InputNewPassword = (props: propsI) => {
  const [localValidation, setLocalValidation] = useState<boolean>(false); // new form have it true unless error
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [localPassword, setLocalPassword] = useState<string>(props.value);

  function handleLocalChange(v: string) {
    setLocalPassword(v);
  }

  function handleBlur(e: any) {
    props.onChange(e.target.value);
    props.handleBlur();
  }

  const togglePasswordVisibility = () => {
    setPasswordVisibility((prevState) => !prevState);
  };

  // once error is thrown, then we know validation happened in upper component
  // now validate at local component
  useEffect(() => {
    if (props.error) {
      if (!localValidation) setLocalValidation(true);
    }
  }, [props.error]);

  return (
    <TextFieldContainer>
      <PasswordStrengthBar password={localPassword} />
      <StyledTextField
        error={!!props.error}
        id={props.value}
        label={props?.label}
        type={passwordVisibility ? 'text' : 'password'}
        onChange={(e) => handleLocalChange(e.target.value)}
        onBlur={handleBlur}
        size='small'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={togglePasswordVisibility}
                onMouseDown={togglePasswordVisibility}
              >
                {passwordVisibility ? (
                  <VisibilityRounded />
                ) : (
                  <VisibilityOffRounded />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {localValidation ? (
        <PasswordReason password={localPassword} />
      ) : (
        <PlaceHolder />
      )}
    </TextFieldContainer>
  );
};

export default InputNewPassword;

const TextFieldContainer = styled.div`
  padding-top: 3px;
  width: 100%;
`;

const PlaceHolder = styled.div`
  height: 20px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  & .MuiInputBase-root {
    // match the border radius around the input field
    border-radius: 10px;
    font-size: 14px;
  }

  & .MuiFormLabel-root {
    // set a smaller label (no input) to adjust to reduced padding
    font-size: 12px;
  }

  & .MuiOutlinedInput-input::placeholder {
    // adjust the placeholder - when field in focus
    padding: 10px;
    font-size: 12px;
    font-style: italic;
  }
  &::placeholder {
    font-style: italic;
    font-size: 12px;
  }
  &:focus {
    outline: none !important;
    border: 1px solid
      ${(p) => (p.error ? '#F44336' : p.theme.palette.textPrimary)};
  }
  // TODO - handle the autofill color
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${(p) => p.theme.palette.backgroundPrimary}
      inset !important;
    -webkit-text-fill-color: ${(p) => p.theme.palette.textPrimary} !important;
  }
`;
