import {UserT} from "@as_core/contexts/UserContext";

export const VENDOR_MASK:{[key: string]: string} = {
  'AS_V_0001': 'AsedaSciences',
  'AS_V_0002': 'Tanguay Laboratories',
  'AS_V_0003': 'Klara Valko',
  'AS_V_0004': 'IndigoBridge',
}

export const VENDOR_EMAIL:{[key: string]: string[]} = {
  'dan.robertson@asedasciences.com': ['AS_V_0001', 'AS_V_0002', 'AS_V_0003', 'AS_V_0004'],
  'brad.calvin@asedasciences.com': ['AS_V_0001', 'AS_V_0002', 'AS_V_0003', 'AS_V_0004'],
  'klara_valko@bio-mimetic-chromatography.com': ['AS_V_0003'],
  'matthew@indigobridgelab.com': ['AS_V_0004'],
}

export type VENDOR = '' | 'AS_V_0001' | 'AS_V_0002' | 'AS_V_0003' | 'AS_V_0004';

const catalogToVendor: {[key: string]: VENDOR} = {
  'AS-SYS-ACS12' : 'AS_V_0001',
  'AS-SYS-ACS12-ACD' : 'AS_V_0001',
  'AS-SCREEN-3RND': 'AS_V_0001',
  'AS-ZBE-ACT236': 'AS_V_0002',
  'AS-ZBE-ACT236-ACD': 'AS_V_0002',
  'AS-ZBE-DTX16-LT' : 'AS_V_0002',
  'AS-ZBE-DTX16-LT-ACD' : 'AS_V_0002',
  'AS-ZBE-DTX16': 'AS_V_0002',
  'AS-ZBE-DTX16-ACD': 'AS_V_0002',
  'AS-ZBE-DTX16-CTG': 'AS_V_0002',
  'AS-CQC-SST': 'AS_V_0004',
  'AS-CQC-SST-ACD': 'AS_V_0004',
  'AS-BMVK-KVC': 'AS_V_0003',
  'AS-BMVK-BMCH': '',
  'AS-BMVK-BMCH-ACD': '',
  '': '',
}


const useVendors= () => {

  const autoAssign = (catalogNumber: string): string => {
    if (!Object.hasOwn(catalogToVendor, catalogNumber)) {
      return '';
    }
    return catalogToVendor[catalogNumber];
  };

  const getRequestType = (cat_no: string): string => {
    if (cat_no === 'AS-BMVK-KVC') {
      return "Klara Consulting"
    } else if (cat_no.includes('AS-BMVK')) {
      return "Biomimetic"
    } else if (cat_no === 'AS-ZBE-DTX16-CTG') {
      return "Zebrafish Consulting"
    } else if (cat_no.includes('AS-ZBE')) {
      return "Zebrafish"
    } else if (cat_no.includes('AS-CQC')) {
      return "QC Solubility"
    } else if (cat_no.includes('AS-SYS')) {
      return "Cell Health"
    }
  }

  const getVendorName = (vendorCode: string): string => {
    if (vendorCode === null || vendorCode === '' || !Object.hasOwn(VENDOR_MASK, vendorCode)) return vendorCode;
    return VENDOR_MASK[vendorCode];
  }

  const getVendorAuthorizationByEmail = (user: UserT): string[] => {
    const vendorEmail: string = user?.authEmail ? user.authEmail : '';
    if (vendorEmail === '' || !Object.hasOwn(VENDOR_EMAIL, vendorEmail)) return [];
    return VENDOR_EMAIL[vendorEmail];
  }

  const getVendorOptions = (): {value: string, label: string}[] => {
    const options = [];
    Object.keys(VENDOR_MASK).forEach((vendor) => {
      options.push({value: vendor, label: VENDOR_MASK[vendor]});
    });
    return options;
  }

  return {
    autoAssign,
    getRequestType,
    getVendorName,
    getVendorAuthorizationByEmail,
    getVendorOptions
  };
};

export default useVendors;
