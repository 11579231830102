import React from 'react';
import { createRoot } from 'react-dom/client'
import { StyleProvider } from "@theme/AppStyles";
import { BrowserRouter } from 'react-router-dom';

const render = () => {
  const App = require('./App').default;

  const container = document.getElementById('root');
  const root = createRoot(container)

  root.render(
    <StyleProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StyleProvider>
  );
};

render();