import { useState } from 'react';
import _ from 'lodash';
import { RequestT } from '../types';
import {
  ActionButtons,
  FormLabel,
  Row,
} from '@as_core/forms/FormStyles';
import Button from '@as_core/account/fields/Button';
import styled from 'styled-components/macro';
import { FlexItem } from '@as_core/elements/flexStyles';
import SingleSelect from "@as_core/controls/inputs/SingleSelect";
import useVendors from "@subApps/requests/useVendors";

type AssignUpdatesT = {
  'assigned_to': string
}

type PropsT = {
  request: RequestT;
  handleUpdate?: (id: string, updates: any) => void;
  handleCancel?: () => void;
}

const debug = false;
export const Assign = (props:PropsT) => {
  const { request, handleUpdate} = props;
  const { getVendorOptions } = useVendors();
  const [updates, setUpdates] = useState<AssignUpdatesT>({
    'assigned_to': _.get(request, 'assigned_to', '')
  });

  const _handleUpdates = (field:string, value:string) => {
    debug && console.log(field, value);
    setUpdates((prev) => ({...prev, [field]: value }));
  }

  const handleCancel = () => {
    debug && console.log('UpdateRequest | Form Assign | Cancel Clicked', props?.handleCancel);
    props?.handleCancel && props.handleCancel();
  }

  debug && console.log('Assign | updates', updates);
  const disabled = updates['assigned_to'] === null || updates['assigned_to'].length === 0 || updates['assigned_to'] === request['assigned_to'];

  return (
    <Container key={'acknowledge-bug-report-form'}>
      <Row width='300px' align={'flex-start'}>
        <FormLabel width={70}>Assign To:</FormLabel>
        <FlexItem>
          <SingleSelect
            id='assigned_to_select'
            label='Select Assignee'
            width={200}
            value={Object.hasOwn(updates, 'assigned_to') ? updates['assigned_to'] : ''}
            options={getVendorOptions()}
            onChange={(e) => _handleUpdates('assigned_to', e.target.value)}
          />
        </FlexItem>
      </Row>
      <ActionButtons>
        <Button type='cancel' width={100} label={'Cancel'} onClick={() => handleCancel()} />
        <Button
          type='submit'
          width={100}
          label={'Save Changes'}
          disabled={disabled}
          onClick={disabled ? ()=>{} : () => handleUpdate(request?.id, updates)}
        />
      </ActionButtons>
    </Container>
  );
};

export default Assign;

const Container = styled.div`
  width: calc(100% - 30px);
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
`;