import SciScore from '@components/icons/IconSciscore';
import Icon3RnD from '@components/icons/Icon3RnD';
import OurBiologyIcon from '@components/icons/ourBiology.icon';

export const asedaCards = [
  {
    title: 'Aseda Sciences Website',
    authorizedOnly: false,
    newTab: true,
    icon: (
      <img
        src={'/img/AsedaSciences_logo.svg'}
        alt={'AsedaSciences'}
        width={64}
      />
    ),
    link: 'https://asedasciences.com',
    description: 'Look at the main AsedaSciences website for more information',
  },
  {
    title: '3RnD Application',
    authorizedOnly: false,
    newTab: true,
    icon: <Icon3RnD size={64} />,
    link: 'https://app-prd.thewarriors3.com',
    description: 'High-performance data analysis and visualization',
  },
  {
    id: 'AS_SciScore',
    title: 'SciScore',
    authorizedOnly: true,
    newTab: true,
    icon: <SciScore size={64} />,
    link: 'https://sciscore.asedasciences.com',
    description: 'Score methods section of a manuscript before submission',
  },
];

export const toolsCards = [
  {
    title: 'Request Handling',
    authorizedOnly: true,
    newTab: false,
    icon: <OurBiologyIcon size={64} />,
    link: '/requests/open',
    description: 'Assay/Request Handling for AsedaSciences Partners',
  }
];
