import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components/macro';
import PgHome from '@pages/PgHome';
import PgAuthentication from "@pages/public/PgAuthentication";
import PgUserRegistration from '@pages/PgUserRegistration';
import PgRequests from '@subApps/requests/PgRequests';

const RoutesProtected = () => {
  return (
    <Content>
      <Routes>
        <Route path='/' element={<Navigate replace to='/home' />} />
        <Route path='/home' element={<PgHome />} />
        <Route path='/user/Registration' element={<PgUserRegistration />} />
        <Route path='/user/:action' element={<PgAuthentication />} />
        <Route path='/requests/:action' element={<PgRequests />} />
        <Route path='/requests/:action/:id' element={<PgRequests />} />
        <Route path='/*' element={<Navigate replace to='/' />} />
        <Route path='*' element={<Navigate replace to='/' />} />
        {/* handle 404 issue on Amazon S3-Hosted */}
      </Routes>
    </Content>
  );
};

export default RoutesProtected;

const Content = styled.div`
  position: absolute;
  margin: 0;
  top: 0;
  left: 50px;
  width: calc(100vw - 50px);
  height: 100vh;
  background: ${(p) => p.theme.palette.backgroundPrimary};
`;
