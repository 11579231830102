import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import TextField from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";

const VALID_COUNTRIES = [
  "", "United States", "United Kingdom", "Ireland {Republic}", "Spain",
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua & Deps",
  "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas",
  "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin",
  "Bhutan", "Bolivia", "Bosnia Herzegovina", "Botswana", "Brazil", "Brunei",
  "Bulgaria", "Burkina", "Burundi", "Cambodia", "Cameroon", "Canada",
  "Cape Verde", "Central African Rep", "Chad", "Chile", "China", "Colombia",
  "Comoros", "Congo", "Congo {Democratic Rep}", "Costa Rica", "Croatia", "Cuba",
  "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic",
  "East Timor", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea",
  "Estonia", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia",
  "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau",
  "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran",
  "Iraq", "Ireland {Republic}", "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan",
  "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea North", "Korea South", "Kosovo",
  "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya",
  "Liechtenstein", "Lithuania", "Luxembourg", "Macedonia", "Madagascar", "Malawi",
  "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius",
  "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco",
  "Mozambique", "Myanmar, {Burma}", "Namibia", "Nauru", "Nepal", "Netherlands",
  "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman", "Pakistan", "Palau",
  "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal",
  "Qatar", "Romania", "Russian Federation", "Rwanda", "St Kitts & Nevis", "St Lucia",
  "Saint Vincent & the Grenadines", "Samoa", "San Marino", "Sao Tome & Principe",
  "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore",
  "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Sudan",
  "Sri Lanka", "Sudan", "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria",
  "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad & Tobago",
  "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates",
  "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City",
  "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
]

interface propsT {
  id: string;
  label?: string;
  value: string;
  onChange: any;
  placeHolder?: string;
  activeUpdate?: boolean;
  width?: number;
  error?: boolean;
  handleBlur?: ()=>void;
}

const InputCountry = (props: propsT) => {
  const {value, onChange} = props;
  const [localValue, setLocalValue] = useState<string>(value || '');
  const [localValidation, setLocalValidation] = useState<boolean>(false); // new form have it true unless error

  let error = localValidation ? localValue === '' : props.error;
  function handleChange(e) {
    setLocalValue(e.target.value);
  }

  function handleBlur() {
    props.handleBlur();
    onChange(localValue);
  }

  // once error is thrown, then we know validation happened in upper component
  // now validate at local component
  useEffect(() => {
    if (props.error) {
      if (!localValidation) setLocalValidation(true);
    }
  }, [props.error]);

  return (
      <CountryFieldContainer>
        <StyledTextField
            id={props.value + '_' + props?.label}
            key={props.value + '_' + props?.label}
            select
            label={props.label}
            value={localValue}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error}
        >
          {VALID_COUNTRIES.map((item, index) => (
              <StyledMenuItem key={`country_${index}`} value={item}>
                {item}
              </StyledMenuItem>
          ))}
        </StyledTextField>
      </CountryFieldContainer>
  );
};

export default InputCountry;

const CountryFieldContainer = styled.div`
  padding-top: 3px;
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  & .MuiInputBase-root {  // match the border radius around the input field
    border-radius: 10px;
    font-size: 14px;
  }

  & .MuiOutlinedInput-input {  // reduce the padding for more compact form
    padding: 9px;
  }

  & .MuiFormLabel-root {  // set a smaller label (no input) to adjust to reduced padding
    font-size: 12px;
  }

  & .MuiOutlinedInput-input::placeholder {  // adjust the placeholder - when field in focus
    padding: 10px;
    font-size: 12px;
    font-style: italic;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  & .MuiButtonItem-root {
    font-size: 16px;
  }
`;
