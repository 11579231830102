import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { UserContext } from '@as_core/contexts/UserContext';
import { Row, StyledA } from './FormStyles';
import UserAccountPolicies from '../fields/UserAccountPolicies';
import {
  fromDotNotation,
  isValidField,
  isFormValid,
  renderAccountInputField,
} from '../utils';
import { userFormFields } from './config';
import useCognito from '@as_core/account/useCognito';
import Button from '@as_core/account/fields/Button';
import useUserRegistrationInfo from '@utils/useUserRegistrationInfo';
import ErrorMessage from '@as_core/account/fields/ErrorMessage';
import { registerTooltipInfo } from './config';
import AppDialogHeader from '@as_core/account/components/AppDialogHeader';
import {useNavigate} from "react-router-dom";
import {APP_CONFIG} from "@app_config/app";

// note -- handle all messages internally until everything is done so that a new component does not get
// created -- and maintain user information

const debug = false;
const SignUp = ({ setMessage }) => {
  const [userDataValidationKeys, setUserDataValidationKeys] = useState<string[]>([]);
  const [userData, setUserData] = useState({ 'address.billing': true });
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
  const [registeringUser, setRegisteringUser] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [clickedOnce, setClickedOnce] = useState<boolean>(false);
  const [fieldUpdated, setFieldUpdated] = useState<boolean>(false);
  const { setUser } = useContext(UserContext);
  const { login, createAccount, getAuthRoles } = useCognito();
  const { createUserRegistrationInfo } = useUserRegistrationInfo();
  const navigate = useNavigate();

  // capture the user input to update validation state as needed
  const handleBlur = () => {
    if (clickedOnce) {
      const newIsValid = isFormValid('create', userData, userDataValidationKeys, setErrorMessage);
      setIsValid(newIsValid);
      setFieldUpdated(true);
    }
  };

  // set appropriate fields in the userData object
  const handleChange = (key:string, value:string) => {
    setUserData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = () => {
    if (!clickedOnce) setClickedOnce(true);
    if (isFormValid('create', userData, userDataValidationKeys, setErrorMessage)) {
      setCreatingAccount(true);
      createAccount(userData['email'], userData['password'])
        .then(
          // successfully created the user account -- ignore the result
          () => {
            setCreatingAccount(false);
            // authenticate
            setRegisteringUser(true);
            login(userData['email'], userData['password'])
              .then((result) => {
                // set the user information from the userContext
                const userSession = result;
                const userId = userSession.getIdToken().payload;
                const token = userSession.getAccessToken().getJwtToken();
                setUser((prev) => ({
                  ...prev,
                  isAuthenticated: true,
                  authId: userId['cognito:username'],
                  authEmail: userId.email,
                  authSession: result,
                  authRoles: getAuthRoles(result)
                }));
                // register user and set their information
                const info = fromDotNotation(userData);
                if (Object.hasOwn(info, 'password')) delete info['password']; // make sure password is not sent
                info['system'] = APP_CONFIG.system;
                debug && console.log('info for creating user', info);
                createUserRegistrationInfo(token, info)
                  .then((response) => {
                    // @ts-ignore
                    if (response?.data.length) {
                      const data = response?.data[0];
                      // as it is a new user, set to isAppAuthorized and add the zero credits
                      setUser((prev) => ({
                        ...prev,
                        regInfo: data?.user,
                        isRegistered: true,
                        isAppAuthorized: false,
                      }));
                      // store locally in case user hits reload -- or for stripe direct / redirect
                      // Note that on create the data returned included object id and user key
                      const dataKey = 'storedUserRegData_' + userId.sub;
                      debug && console.log(`SignUp | saving UserReg data to "${dataKey}" | UserReg data:`, data?.user);
                      localStorage.setItem(dataKey, JSON.stringify(data?.user));
                      navigate('/home');
                    } else {
                      setRegisteringUser(false);
                      console.error('RegistrationException: ', response?.errors);
                      setErrorMessage(response?.errors[0]);
                    }
                  })
                  .catch((error) => {
                    setRegisteringUser(false);
                    console.error('CreateUserRegistrationAPIError: ', error);
                    setErrorMessage(error);
                  });
              })
              .catch((error) => {
                // authentication error
                setRegisteringUser(false);
                console.error('CognitoAuthenticationException: ' + error);
                setErrorMessage(error);
              });
          }
        )
        .catch((error) => {
          setCreatingAccount(false);
          console.error('CognitoSignupException: ' + error);
          const cleanError = error.replace('UsernameExistsException: ', 'Error: ');
          setErrorMessage(cleanError);
        });
    }
  };
  // update form when onceSubmitted and field changes
  useEffect(() => {
    if (clickedOnce) {
      if (fieldUpdated) setFieldUpdated(false);
    }
  }, [clickedOnce, fieldUpdated, userData]);

  // set validation keys on first creation
  useEffect(() => {
    let userDataKeys = [];
    userFormFields.forEach((group) => {
      group.forEach((field) => {
        if (field.value !== 'password') userDataKeys.push(field.value);
      });
    });
    userDataKeys.push('organization.type');
    setUserDataValidationKeys(userDataKeys);
  }, []);

  return (
    <SignUpForm key={'user-sign-up'}>
      {creatingAccount ? (
        <StatusMessage>Creating User Account</StatusMessage>
      ) : registeringUser ? (
        <StatusMessage>Registering User Information</StatusMessage>
      ) : (
        <UserForm key={'user-sign-up-form'}>
          <AppDialogHeader message={'Create an Account'} tooltip={registerTooltipInfo} />
          <Row height={'18px'}>
            {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
          </Row>
          {userFormFields.map((row, index) => (
            <Row width={'100%'} key={'row_' + index}>
              {row.map((info, row_index) =>
                renderAccountInputField('create', info, `${index}_${row_index}`,
                userData, handleChange, clickedOnce && !isValidField(info.value, userData),
                handleBlur)
              )}
            </Row>
          ))}
          <Row>
            <UserAccountPolicies />
          </Row>
          <Row>
            <Button
              type={'submit'}
              label={'Create Account'}
              onClick={handleSubmit}
            />
          </Row>
          <Row>
            <StyledA
                key={'return'}
                href={'/user/login'}
                size={'12px'}
                topMargin={'10px'}
            >
              return to sign-in
            </StyledA>
            { fieldUpdated && isValid? <></> : null}
          </Row>
        </UserForm>
      )}
    </SignUpForm>
  );
};

export default SignUp;

export const SignUpForm = styled.div`
  width: 550px;
  height: max-content;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StatusMessage = styled.div`
  display: flex;
  padding: 50px;
  font-size: 24px;
  border: 2px solid ${(p) => p.theme.palette.accentPrimary};
  border-radius: 30px;
`;

export const UserForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 10px;
  min-width: 525px;
  border: 1px solid ${(p) => p.theme.palette.backgroundQuaternary};
  border-radius: 15px;
  background: ${(p) => p.theme.palette.backgroundSecondary};
`;
