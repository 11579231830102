import { AxiosRequestConfig } from 'axios';
import { User } from './api/';
import { handleError } from '@utils/misc';

export interface UserAppT {
  name: string;
  email: string;
  orgId: string;
  roles: string[];
}

export interface ResponseT {
  errors: Array<string>;
  data: Array<any>;
}

const useUserAppInfo = () => {

  const getUserInfo = async (token, params): Promise<ResponseT> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await User.get(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getUser ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  const deleteUserInfo = async (token, params): Promise<ResponseT> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await User.delete(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('deleteUser ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  const updateUserInfo = async (token,params): Promise<ResponseT> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await User.update(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getUser ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  return {
    getUserInfo,
    updateUserInfo,
    deleteUserInfo,
  };
};
export default useUserAppInfo;
