import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const OurBiologyIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M17.7004 16.3C17.5004 16.1 17.1004 16.1 16.9004 16.3C16.6004 16.6 16.3004
          16.8 16.0004 17L15.4004 16C16.7004 15 17.6004 13.5 17.6004 11.7C17.6004 8.8 15.2004
          6.4 12.3004 6.4C10.3004 6.4 8.60039 7.5 7.70039 9.2L6.70039 8.8C7.00039 8.2 7.40039
          7.7 7.90039 7.2C8.10039 7 8.10039 6.6 7.90039 6.4C7.70039 6.2 7.30039 6.2 7.10039
          6.4C5.70039 7.8 4.90039 9.7 4.90039 11.7C4.90039 12 5.20039 12.3 5.50039 12.3C5.80039
          12.3 6.10039 12 6.10039 11.7C6.10039 11 6.20039 10.4 6.40039 9.8L7.50039 10.2C7.40039
          10.7 7.30039 11.2 7.30039 11.7C7.30039 14.6 9.70039 17 12.6004 17C13.4004 17 14.1004
          16.8 14.7004 16.5L15.3004 17.5C15.0004 17.7 14.6004 17.8 14.3004 17.9C14.0004 18
          13.8004 18.3 13.9004 18.6C14.0004 18.9 14.2004 19 14.4004 19H14.5004C15.1004 18.8
          15.7004 18.6 16.3004 18.2C16.9004 17.9 17.4004 17.5 17.9004 17C17.9004 16.9 17.9004
          16.5 17.7004 16.3ZM8.20039 11.8C8.20039 9.5 10.1004 7.7 12.3004 7.7C14.5004 7.7
          16.4004 9.6 16.4004 11.8C16.4004 14 14.5004 15.9 12.3004 15.9C10.1004 15.9 8.20039
          14.1 8.20039 11.8Z" fill={color}/>
          <path d="M20.9 19.6C22.7 17.5 23.7 14.8 23.7 12.1C23.7 5.7 18.5 0.5 12.1 0.5C5.7 0.5
          0.5 5.6 0.5 12C0.5 18.4 5.7 23.6 12.1 23.6C14.9 23.6 17.5 22.6 19.6 20.8C19.8 20.6
          19.8 20.4 19.6 20.2C19.4 20 19.134 19.9357 18.934 20.1357C17.034 21.8357 14.6 22.6
          12 22.6C6.2 22.5 1.71165 17.9 1.71165 12C1.71165 6.1 6.2 1.58617 12.1 1.58617C18
          1.58617 22.5209 6.1 22.5209 12C22.5209 14.6 21.8111 17.1 20.1111 19C20.0209 19.1806
          20.0845 19.3611 20.2449 19.5417C20.2622 19.5611 20.2806 19.5806 20.3 19.6C20.5
          19.8 20.7 19.7 20.9 19.6Z" fill={colorHighlight}/>
          <path d="M18.3 8.59976C19.8994 8.40783 19.962 7.18763 19.412 6.131C19.1024 5.53608
          18.56 5.03873 18.0829 4.6939C16.7584 3.7366 16.1 3.79976 15.5 4.59976C15 5.29976
          15.1686 6.25254 15.8686 6.75254C16.8686 7.25254 17.1 8.69976 18.3 8.59976ZM16.4
          6.09976C15.6 5.49976 16.5 4.39976 17.3 4.89976C16.6 5.29976 17.4 6.19976 17.8
          5.59976L17.9 5.49976C18.3 5.89976 19.1 6.49976 19 7.09976C19 7.79976 18.1
          7.89976 17.7 7.39976C18.4 7.09976 17.7 6.29976 17.2 6.79976C16.9 6.49976
          16.6 6.29976 16.4 6.09976Z" fill={colorHighlight}/>
          <path d="M6.63657 15.993C5.03712 16.1849 4.97457 17.4051 5.52452 18.4618C5.83417
          19.0567 6.37648 19.554 6.85361 19.8989C8.17817 20.8562 8.83657 20.793 9.43657
          19.993C9.93657 19.293 9.76791 18.3402 9.06791 17.8402C8.06791 17.3402 7.83657
          15.893 6.63657 15.993ZM8.53657 18.493C9.33657 19.093 8.43657 20.193 7.63657
          19.693C8.33657 19.293 7.53657 18.393 7.13657 18.993L7.03657 19.093C6.63657
          18.693 5.83657 18.093 5.93657 17.493C5.93657 16.793 6.83657 16.693 7.23657
          17.193C6.53657 17.493 7.23657 18.293 7.73657 17.793C8.03657 18.093 8.33657
          18.293 8.53657 18.493Z" fill={colorHighlight}/>
        </g>
      </svg>
    </>
  )
}

export default OurBiologyIcon;