import { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const ViewIcon = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.palette.textPrimary;
  const colorHighlight = style.palette.accentPrimary;
  return (
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path fill={color} d="M12,19.7c-4.2,0-7.7-3.4-7.7-7.7c0-4.2,3.4-7.7,7.7-7.7c4.2,0,7.7,3.4,7.7,7.7C19.7,16.2,16.2,19.7,12,19.7z M12,5.3
	c-3.7,0-6.7,3-6.7,6.7s3,6.7,6.7,6.7s6.7-3,6.7-6.7S15.7,5.3,12,5.3z"/>
        <path fill={colorHighlight} d="M12,7.2c-0.5,0-0.8,0-1,0.3c0.5,0.5,0.6,1,0.6,1.8c0,1.4-1,2.4-2.4,2.4c-0.6,0-1.4-0.3-1.6-0.8C7.2,11,7.2,11.5,7.2,12
	c0,2.7,2.1,4.8,4.8,4.8s4.8-2.1,4.8-4.8S14.7,7.2,12,7.2z"/>
        <path fill={color} d="M24,15.3c-3.1,1.9-7.3,3.9-12,3.9c-4.7,0-8.9-1.9-12-3.9v1.8c3.2,1.9,7.3,3.6,12,3.6s8.8-1.7,12-3.6V15.3z"/>
        <path fill={color} d="M24,8.7V6.9C20.8,5,16.7,3.3,12,3.3C7.3,3.3,3.2,5,0,6.9v1.8c3.1-1.9,7.3-3.9,12-3.9C16.7,4.8,20.9,6.7,24,8.7z"/>
      </svg>
  );
};

export default ViewIcon;
