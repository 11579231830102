import React from 'react';
import styled from 'styled-components/macro';

const RadioButton = ({ id, checked = false,
          onClick, error=false, children }) => {
  return (
    <Container>
      <StyledInput
        key={`radiobutton-${id}`}
        checked={checked}
        error={error}
        onClick={onClick}
      />
      {children}
    </Container>
  );
};

export default RadioButton;

const Container: any = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.div<{checked?: boolean, error?: boolean}>`
  color: ${(p) => p.theme.palette.textPrimary};
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 6px;
  border: 1px solid ${(p) => p.error ? '#F44336' :
    p.checked ? p.theme.palette.textPrimary : p.theme.palette.textPrimary};
  background-color: ${(p) => p.checked ? p.theme.palette.accentPrimary : null };
  :hover {
    cursor: pointer;
  }
`;
