import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const SubscriptionsIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill={colorHighlight} d="M13.5,10.2c-0.1-0.6-0.3-1.1-0.9-1.2v2.5c1.2,0.3,2.2,0.8,2.2,2.4c0,1.4-0.9,2.2-2.2,2.4v1h-1.1v-1
		c-1.2-0.1-2.3-0.7-2.4-2.4l1.2-0.2c0.2,0.9,0.4,1.4,1.3,1.5v-2.6c-1.1-0.3-2.2-0.7-2.2-2.4c0-1.4,1-2.2,2.2-2.4v-1h1.1v1
		c1.1,0.1,1.9,0.8,2.1,2.2L13.5,10.2z M11.7,11.2V8.9C11,9.1,10.8,9.5,10.8,10C10.8,10.6,11.1,10.9,11.7,11.2z M12.6,12.8v2.3
		c0.5-0.1,0.9-0.5,0.9-1.1C13.5,13.3,13.1,13,12.6,12.8z"/>
          <path fill={color} d="M3.8,12c0-3.8,2.5-6.9,6-7.9V5l3-1.7l-3-1.7v1c-4.3,1-7.5,4.9-7.5,9.5c0,4.6,3.3,8.5,7.6,9.5V20C6.4,19,3.8,15.8,3.8,12z"/>
          <path fill={color} d="M21.8,12c0-4.6-3.2-8.5-7.6-9.5v1.5c3.5,1,6.1,4.2,6.1,7.9c0,3.8-2.5,6.9-6,7.9V19l-3,1.7l3,1.7v-1
	C18.5,20.4,21.8,16.6,21.8,12z"/>
      </svg>
    </>
  )
}

export default SubscriptionsIcon;