import React, { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const FulfillmentUnassignedIcon = ({
    size = 24,
  }) => {
  const [style] = useContext(StyleContext);
  const color = (style.name === "dark") ? "white" : "black";
  const colorHighlight = style.palette.accentPrimary;
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color}  d="M18,9.6h-8.5c-.6,0-1-.4-1-1s.4-1,1-1h8.5c.6,0,1,.4,1,1s-.5,1-1,1Z"/>
      <circle fill={color}  cx="6.2" cy="8.6" r="1"/>
      <circle fill={color}  cx="6.2" cy="19.1" r="1"/>
      <circle fill={color}  cx="6.2" cy="13.8" r="1"/>
      <path fill={color}  d="M9.7,18.1h-.2c-.6,0-1,.4-1,1s.4,1,1,1h.6c-.2-.7-.4-1.3-.4-2Z"/>
      <path fill={color}  d="M11.4,12.8h-1.9c-.6,0-1,.4-1,1s.4,1,1,1h.8c.2-.7.6-1.4,1.1-2Z"/>
      <path fill={color} d="M11.4,22.5h-6.5c-.6,0-1.1-.5-1.1-1.1V5.1c0-.6.4-1.1,1.1-1.1h14.3c.6,0,1.1.5,1.1,1.1v5.2c.5.2,1,.5,1.5.8v-6c0-1.5-1.2-2.6-2.6-2.6h-5.2c.1-.2.2-.4.2-.7,0-.9-.9-1.6-2.1-1.6s-2.2.7-2.2,1.6.1.5.2.7h-5.2c-1.4,0-2.6,1.2-2.6,2.6v16.3c0,1.4,1.2,2.6,2.6,2.6h8c-.6-.4-1.1-.9-1.5-1.5ZM12,1.2c.6,0,1.1.3,1.1.6s-.5.6-1.1.6-1.1-.3-1.1-.6.5-.6,1.1-.6Z"/>
      <path fill={colorHighlight} d="M17.5,11.3c-3.5,0-6.3,2.8-6.3,6.3s2.8,6.3,6.3,6.3,6.3-2.8,6.3-6.3-2.8-6.3-6.3-6.3ZM17.8,21.9h-1.5v-1.4h1.5v1.4ZM17.8,19v.5h-1.5v-.6c0-1.7,2-2,2-3.3s-.5-1-1.2-1-1.3.5-1.3.5l-.8-1s.8-.9,2.3-.9,2.6.8,2.6,2.3-2.1,2.2-2.1,3.5Z"/>
    </svg>
  )
}

export default FulfillmentUnassignedIcon;