export const handleError = (err) => {
  if (err?.response?.status === 400) {
    console.log('ERROR', err?.response?.data?.errors);
    err?.response?.data?.errors?.forEach(() => {
      // alert(element);
    });
  } else {
    err?.response?.status && console.log('ERROR CODE', err?.response?.status);
    err?.response?.data && console.log('ERROR', err?.response?.data);
  }
};

export function truncateString(str, maxLength, trailing=0) {
  if (str === undefined || !str) return '';
  if (typeof str === 'number') {
    return str.toString();
  }
  if (str.length <= maxLength) {
    return str;
  } else if (trailing) {
    const strLen = str.length;
    return str.slice(0, maxLength-trailing) + '...' + str.slice(strLen-trailing, strLen);
  } else {
    return str.slice(0, maxLength) + '...';
  }
}
