import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { UserContext, UserEmpty, UserT } from '@as_core/contexts/UserContext';
import { MenuContext } from '@as_core/contexts/MenuContext';
import AuthWrapper from '@as_core/account/AuthWrapper';
import LoadingMessage from '@as_core/account/LoadingMessage';
import SideMenu from '@as_core/menus/SideMenu';
import RoutesProtected from './app_config/RoutesProtected';
import RoutesPublic from './app_config/RoutesPublic';
import PgNotDomainAllowed from "@pages/public/PgNotDomainAllowed";
import useUserAppInfo from '@utils/useUserAppInfo';
import {useNavigate, useLocation} from "react-router-dom";
import {APP_CONFIG} from "@app_config/app";

const debug = false;

const App = () => {
  const { getUserInfo } = useUserAppInfo();
  const [isDomainAllowed, setIsDomainAllowed] = useState<boolean>(true);
  const [isAppDataLoading, setIsAppDataLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserT | null>(UserEmpty);
  const navigate = useNavigate();
  const location = useLocation();

  debug && console.log('App | pathname:', location.pathname, ' user:', user);

  // use for side menu
  const [activeMenuId, setActiveMenuId] = useState<string>(null);
  const menuParams = React.useMemo(
      () => ({ activeMenuId, setActiveMenuId }),
      [activeMenuId, setActiveMenuId]
  );

  // Get the User Application Account Information -- after authenticated and registration loaded
  useEffect(() => {
    if (user.isRegistered && !user.isAppAuthorized && !isAppDataLoading) {
      // Check Authorization in case hosted app with restricted domain
      if (APP_CONFIG?.restrictedDomain) {
        const allowedList =  APP_CONFIG.restrictedDomain.split(';');
        allowedList.push('asedasciences.com');
        const userDomain = user.authEmail.includes('@') ? user.authEmail.split('@')[1].toLowerCase() : '';
        console.log('allowedList: ', allowedList, 'userDomain', userDomain);
        setIsDomainAllowed(allowedList.includes(userDomain));
      }
      let appInfo = {};
      setIsAppDataLoading(true);
      // Add the user App info here
      let token = user.authSession.getAccessToken().getJwtToken();
      getUserInfo(token, {login: true}).then((response) => {
        // @ts-ignore
        debug && console.log('App | User API Response:', response);
        if (response?.data.length) {
          appInfo = response?.data[0];
          debug && console.log('App | Application data:', appInfo);
        } else {
          console.error('App | user response data missing | error', response?.errors);
        }
        setUser((prev) => ({...prev, isAppAuthorized: true, appInfo: appInfo}));
        setIsAppDataLoading(false);
        // handle redirect if coming from sign-in or new user registration
        if (['/user/login', '/user/new', '/user/register'].includes(location.pathname)) navigate('/');
      })
      .catch((reason) => {
        console.error('App | user API error:', reason);
        setIsAppDataLoading(false);
      });
    }
  }, [user, isAppDataLoading]);

  return (
      <UserContext.Provider value={{ user, setUser }}>
        <AuthWrapper>
          <MenuContext.Provider value={menuParams}>
            <Container>
              {isAppDataLoading ? (
                  <LoadingMessage message={'Loading User Application Data'} />
              ) : user.isLoading ? (
                  <LoadingMessage message={'Checking Authentication'} />
              ) : user.isAuthenticated && user.isRegistered ?
                isDomainAllowed ?
                  <>
                    <SideMenu />
                    <RoutesProtected />
                  </>
                :
                  <PgNotDomainAllowed />
              :
                  <RoutesPublic />
              }
            </Container>
          </MenuContext.Provider>
        </AuthWrapper>
      </UserContext.Provider>
  );
};
export default App;

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.palette.backgroundPrimary};
  // disable selection
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
`;
