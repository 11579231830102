import React, { useContext, useEffect, useState } from 'react';
import PageContainer from '@as_core/pages/PageContainer';
import { UserContext } from '@as_core/contexts/UserContext';
import useCognito from "@as_core/account/useCognito";
import { Tooltip } from '@mui/material';
import { PageCenter, StyledDiv, StyledTextField, StyledButton, ButtonContainer } from './pages_styles/userRegistration_style';
import { FlexRow, FlexItem, FlexSpacer } from '@as_core/elements/flexStyles';
import { TextTitle, TextSmall, TextSpace} from '@as_core/elements/textStyles';
import useUserRegistrationInfo from "@utils/useUserRegistrationInfo";
import ButtonText from "@as_core/elements/ButtonText";
import DataUploadIcon from "@as_core/icons/dataUpload.icon";
import {useNavigate} from "react-router-dom";
import {APP_CONFIG} from "@app_config/app";

const modeName = {
  'edit': 'Edit/Update Registration',
  'create': 'Register'
}

const debug = false;
const PgUserRegistration = () => {
  const { user, setUser } = useContext(UserContext);
  debug && console.log('user: ', user);
  const [mode, setMode] = useState('create');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [town, setTown] = useState('');
  const [zipCode, setZipCode] = useState('');

  const [isFormValid, setIsFormValid] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { createUserRegistrationInfo, updateUserRegistrationInfo } = useUserRegistrationInfo();
  const navigate = useNavigate();
  const { getToken } = useCognito();

  useEffect(()=>{
    if (Object.keys(user.regInfo).length !== 0) {
      setMode('edit');
      setFirstName(user.regInfo.name?.first);
      setLastName(user.regInfo.name?.last);
      setAddress(user.regInfo.address?.address);
      setCountry(user.regInfo.address?.country);
      setState(user.regInfo.address?.state);
      setTown(user.regInfo.address?.town);
      setZipCode(user.regInfo.address?.postalCode);
      setIsFormValid(true);
    }
  },[user]);

  useEffect(() => {
    const isValid =
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      address.trim() !== '' &&
      country.trim() !== '' &&
      state.trim() !== '' &&
      town.trim() !== '' &&
      zipCode.trim() !== '';
    setIsFormValid(isValid);
  }, [firstName, lastName, address, country, state, town, zipCode]);

  const handleReset = () => {
    setFirstName('');
    setLastName('');
    setAddress('');
    setCountry('');
    setState('');
    setTown('');
    setZipCode('');
    setIsFormValid(false);
  };

  const handleClick = () => {
    // Send the data to the backend via an API
    let info = {
      "name": {
        "first": firstName,
        "last": lastName
      },
      "address": {
        "address": address,
        "town": town,
        "state": state,
        "country": country,
        "postalCode": zipCode
      }
    }
    // create the user
    if (mode === 'create') {
      info["email"] = user.authInfo.email;
      info['system'] = APP_CONFIG.system;
      createUserRegistrationInfo(getToken(), info).then(
          (response) => {
            // @ts-ignore
            if (response?.data.length) {
              const data = response?.data[0];
              debug && console.log("App | Registration data:", data);
              let updated_user = user;
              updated_user.regInfo = data;
              updated_user.isRegistered = true;
              updated_user.isAuthorized = true;
              setUser(updated_user);
            } else {
              debug && console.log("App | response data missing | error", response?.errors);
            }
            navigate('/');
            navigate(0);  // force refresh as user is now registered
          });
    } else {
      updateUserRegistrationInfo(getToken(), info).then(
          (response) => {
            // @ts-ignore
            if (response?.data.length) {
              const data = response?.data[0];
              debug && console.log("App | Registration data:", data);
              let updated_user = user;
              updated_user.regInfo = data;
              setUser(updated_user);
            } else {
              debug && console.log("App | response data missing | error", response?.errors);
            }
            navigate('/user');
          });
    }
  };

  const disabledTooltip = 'Please fill all the fields to validate the form';

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };
  const formWidth='600px';

  let saveButtonLabel = "Register";
  if (mode === "edit") {
    saveButtonLabel = "Save Changes"
  }

  return (
      <PageContainer title="User Registration" >
        <PageCenter>
        <StyledDiv>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginBottom: '0.5rem',
              padding: '0.25rem',
            }}
          >
            <FlexRow width={"100%"} h_centered>
              <FlexItem>
                <TextTitle>{ modeName[mode]}</TextTitle>
              </FlexItem>
            </FlexRow>
            <FlexRow width={"100%"} h_centered>
              <FlexItem>
                <TextSmall>User Information for email:</TextSmall>
                <TextSpace/>
                <TextSmall>{user.authInfo.email}</TextSmall>
              </FlexItem>
            </FlexRow>
            <FlexRow>
              <FlexItem>
                <TextTitle color={'accent'}>Name</TextTitle>
              </FlexItem>
            </FlexRow>
            <FlexRow width={formWidth} >
              <FlexSpacer/>
              <FlexItem width={'50%'}>
                <StyledTextField
                    label="First Name"
                    variant="standard"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    fullWidth
                    margin="normal"
                    placeholder="John"
                />
              </FlexItem>
              <FlexItem width={formWidth}>
                <StyledTextField
                    label="Last Name"
                    variant="standard"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    fullWidth
                    margin="normal"
                    placeholder="Doe"
                />
              </FlexItem>
            </FlexRow>
            <FlexRow>
              <FlexItem>
                <TextTitle color={'accent'}>Address</TextTitle>
              </FlexItem>
            </FlexRow>
            <FlexRow width={formWidth}>
              <FlexSpacer/>
              <FlexItem width={'100%'}>
                <StyledTextField
                    label="Street Address"
                    variant="standard"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    fullWidth
                    margin="normal"
                    placeholder="123 Main St"
                />
              </FlexItem>
            </FlexRow>
            <FlexRow width={formWidth}>
              <FlexSpacer/>
              <FlexItem width={'40%'}>
                <StyledTextField
                    label="Town"
                    variant="standard"
                    value={town}
                    onChange={(e) => setTown(e.target.value)}
                    fullWidth
                    margin="normal"
                    placeholder="San Francisco"
                />
              </FlexItem>
              <FlexItem width={'30%'}>
                <StyledTextField
                    label="State"
                    variant="standard"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    fullWidth
                    margin="normal"
                    placeholder="California"
                />
              </FlexItem>
              <FlexItem width={'30%'}>
                <StyledTextField
                    label="ZIP Code"
                    variant="standard"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    fullWidth
                    margin="normal"
                    placeholder="12345"
                />
              </FlexItem>
            </FlexRow>
            <FlexRow width={formWidth}>
              <FlexSpacer/>
              <FlexItem width={'40%'}>
                <StyledTextField
                    label="Country"
                    variant="standard"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    fullWidth
                    margin="normal"
                    placeholder="United States"
                />
              </FlexItem>
            </FlexRow>
          </div>

          <ButtonContainer>
            { mode === 'create' ? (
                <ButtonText
                    width={100}
                    label="Reset All"
                    onClick={()=>handleReset()}
                />
            ) : <FlexSpacer/> }
            { !isFormValid ? (
              <Tooltip
                data-testid="tooltipButton"
                title={disabledTooltip}
                placement="top"
                open={isFormValid ? false : isTooltipOpen}
                onClose={handleTooltipClose}
                onOpen={handleTooltipOpen}
              >
                <span>
                  <StyledButton
                    data-testid="formValidation"
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                    disabled={!isFormValid}
                    style={{ backgroundColor: !isFormValid ? 'grey' : '' }}
                  >
                    Validate
                  </StyledButton>
                </span>
              </Tooltip>
                ) : (
                <ButtonText
                    width={150}
                    onClick={handleClick}
                    icon={<DataUploadIcon />}
                    label={saveButtonLabel}
                />
            )}
          </ButtonContainer>
        </StyledDiv>

        </PageCenter>
      </PageContainer>
  );
};

export default PgUserRegistration;

