import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import PageContainer from '@as_core/pages/PageContainer';
import { useParams } from 'react-router-dom';
import { UserContext } from '@as_core/contexts/UserContext';
import { MenuContext } from '@as_core/contexts/MenuContext';
import CenteredMessages, {  MessageItem } from '@as_core/elements/CenteredMessages';
import { CircularProgress } from '@mui/material';
import useAssayRequests from './useAssayRequests';
import RequestsTable from "@subApps/requests/components/RequestsTable";
import { getTitle } from './utils';
import ErrorMessages from "@as_core/elements/ErrorMessages";
import ViewRequest from "@subApps/requests/components/ViewRequest";
import useVendors from "@subApps/requests/useVendors";
import RequestPageHeader from "@subApps/requests/components/RequestPageHeader";

const ADMIN_ROLES = ['aseda-admin', 'aseda-dev', 'aseda-partners'];

export function hasRequiredRole(userRoles: string[], requiredRoles: string[]) {
  return userRoles.some((r) => requiredRoles.includes(r));
}

const debug = false;
const PgRequests = () => {
  const { action, id } = useParams();
  const [requestsUpdated, setRequestsUpdated] = useState<boolean>(false);
  const { user, setUser } = useContext(UserContext);
  const { activeMenuId, setActiveMenuId } = useContext(MenuContext);
  const { getAssayRequestsByStatus } = useAssayRequests();
  const [assayRequests, setAssayRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {getVendorAuthorizationByEmail} = useVendors();

  const userAuthorizations = getVendorAuthorizationByEmail(user);

  debug && console.log('PgRequests | action:', action, 'id:', id, ' user', user);

  if (user.subApp !== 'requests') {
    setUser((prev) => ({
      ...prev,
      subApp: 'requests',
    }));
  }
  const menuId = 'req' + action[0].toUpperCase() + action.slice(1);
  if (activeMenuId !== menuId) setActiveMenuId(menuId);

  // get assay requests counts and updated when flagged by component
  useEffect(() => {
    setIsLoading(true);
    setAssayRequests([]);
    getAssayRequestsByStatus(action)
      .then((response) => {
        debug && console.log('response:', response);
        let requests = response;
        if (!hasRequiredRole(user?.authRoles, ADMIN_ROLES)) {
          requests = response.filter((r) => userAuthorizations.includes[r.assigned_to]);
        }
        setAssayRequests(requests);
        setIsLoading(false);
        setRequestsUpdated(false);
      })
      .catch((err) => {
        console.error("load data error | err", err);
      })
  }, [action, requestsUpdated]);

  debug && console.log('Pg Requests | requestsUpdated', requestsUpdated);
  debug && console.log('Pg Requests | assayRequests', assayRequests);

  return (
    <PageContainer title={getTitle(action)}>
      <Container>
        <RequestPageHeader user={user} userAuthorizations={userAuthorizations}/>
        {hasRequiredRole(user?.authRoles, ADMIN_ROLES) || userAuthorizations.length ? (
          <RequestsListContainer>
            {isLoading ? (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            ) : (
              action === 'details' ?
                <ViewRequest request_id={id}/>
              :
                <RequestsList>
                  { assayRequests === null ?
                    <RequestsInfo>
                      <TopSpace/>
                      <ErrorMessages messages={[
                        <MessageItem>Error reaching API -- Contact Customer Service</MessageItem>
                      ]}/>
                    </RequestsInfo>
                      : assayRequests.length > 0 ? (
                    <RequestsTable
                      action={action}
                      assayRequests={assayRequests}
                      setUpdated={() => setRequestsUpdated(true)}
                    />
                  ) : (
                    <RequestsInfo>
                      <TopSpace/>
                      <ErrorMessages messages={[
                        <MessageItem>No {action} requests</MessageItem>
                      ]}/>
                    </RequestsInfo>
                  )}
                </RequestsList>
            )}
          </RequestsListContainer>
        ) : (
          <CenteredMessages
            messages={[
              <MessageItem color={'accent'}>
                Not Authorized to Access this Tool/Functionality
              </MessageItem>,
              <MessageItem>
                If you should be authorized, please submit a bug report.
              </MessageItem>,
            ]}
          />
        )}
      </Container>
    </PageContainer>
  );
};

export default PgRequests;

const Container = styled.div`
  font-size: 24px;
  color: ${(p) => p.theme.palette.accentPrimary};
  width: 100%;
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
`;

const TopSpace = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
`;

const RequestsListContainer = styled.div`
  width: 100%;
`;

const RequestsList = styled.div`
  display: flex;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
`;

const RequestsInfo = styled.div`
  text-align: center;
  width: 100%;
`;
