import styled from 'styled-components/macro';
import { AssayRequests } from '@utils/api';
import useCognito from '@as_core/account/useCognito';
import { RequestT } from '../types';
import { updateByDot } from '../utils';
import RequestInfoHeader from './RequestInfoHeader';
import Assign from "@subApps/requests/forms/Assign";

function getForm(action:string, currentRequest:RequestT, handleUpdate, handleCancel) {
  switch(action) {
    case 'open':
      return (
        <Assign
          request={currentRequest}
          handleUpdate={handleUpdate}
          handleCancel={handleCancel}
        />
      )
    default:
      console.error('UpdateRequest | unknown form for action', action);
  }
}


type PropsT = {
  action: string;
  request: RequestT;
  setUpdated?: () => void;
  closeDialog?: () => void;
}

const debug = false;
const UpdateRequest = (props:PropsT) => {
  let { action, request} = props;
  debug && console.log('UpdateRequest | action, request:', action, request);
  const { getToken } = useCognito();

  const _handleUpdate = async (id:string, updates: any) => {
    console.log('UpdateRequest | _handleUpdate', id, updates);
    const payload = { };
    Object.keys(updates).forEach((k) => {
        updateByDot(payload, k, updates[k]);
      }
    )
    await AssayRequests.update(getToken(), id, payload);
    props?.closeDialog && props.closeDialog();
    props?.setUpdated && props.setUpdated();
  }

  return (
    <UserEditContainer>
      <RequestInfoHeader action={action} request={request} />
      { getForm(action, request, _handleUpdate, props?.closeDialog) }
    </UserEditContainer>
  );
};

export default UpdateRequest;

const UserEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
