import React, { useState, useEffect } from 'react';
import { FlexColumn, FlexItem } from '@as_core/elements/flexStyles';
import DraggableModal from '@as_core/elements/DraggableModal';
import UpdateRequest from './UpdateRequest';
import BasicTable from '@as_core/tables/BasicTable';
import { ColDef, getRequestsTableRows } from '../utils';
import {useNavigate} from "react-router-dom";

const modalDimensions = {
  open: { height: 200, width: 500 },
  unassigned: { height: 180, width: 500 },
  active: { height: 250, width: 450 },
};

const debug = false;
const RequestsTable = (props) => {
  const { action, assayRequests } = props;
  const [requestsRows, setRequestsRows] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const navigate = useNavigate();
  debug && console.log('RequestsTable: {action}', action);
  debug && console.log('RequestsTable: {assayRequests}', assayRequests);
  debug && console.log('RequestsTable | selectedRequest:', selectedRequest);

  const handleModalClose = () => setIsModalOpen(false);

  const handleClick = (type: string, id: string) => {
    debug && console.log('handleClick ', type, id);
    const matched = assayRequests.filter((r) => r.id === id);
    if (matched.length) {
      setSelectedRequest(matched[0]);
      debug && console.log('handleEditClick: matched', matched);
      switch (type) {
        case 'edit':
          setIsModalOpen(true);
          break;
        default:
          navigate(`/requests/details/${id}`)
      }
    }
  };

  const cols = Object.hasOwn(ColDef, action) ? ColDef[action] : ColDef['open'];
  debug && console.log('RequestsTable: {cols}', cols);

  useEffect(() => {
    setRequestsRows(getRequestsTableRows(action, assayRequests, cols, handleClick));
  }, [action, assayRequests]);

  debug && console.log('RequestsTable: {requestsRows}', requestsRows);


  return (
    <>
      <FlexColumn h_centered width={'100%'}>
        <FlexItem>
          { requestsRows.length ?
            <BasicTable fields={cols} rows={requestsRows} /> :
            <FlexItem>No rows defined </FlexItem>
          }
        </FlexItem>
      </FlexColumn>
      <DraggableModal
        title={'Update Request Report'}
        height={
          Object.hasOwn(modalDimensions, action)
            ? modalDimensions[action].height
            : 600
        }
        width={
          Object.hasOwn(modalDimensions, action)
            ? modalDimensions[action].width
            : 600
        }
        isModalOpen={isModalOpen}
        onCloseClick={handleModalClose}
      >
        <UpdateRequest
          action={action}
          request={selectedRequest}
          setUpdated={props?.setUpdated}
          closeDialog={() => setIsModalOpen(false)}
        />
      </DraggableModal>
    </>
  );
};

export default RequestsTable;
