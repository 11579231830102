import { useContext } from 'react';
import { StyleContext } from '@theme/AppStyles';

const TrashIcon = ({ size = 24 }) => {
  const [style] = useContext(StyleContext);
  const color = style.name === 'dark' ? 'white' : 'black';
  const colorHighlight = style.palette.accentPrimary;
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          stroke={color}
          d='M5.3,6.7c0,0.1,0,0.2,0,0.3l1,10.6c0,2.4,1.1,4.3,2.5,4.3h6.5c1.4,0,2.5-2,2.5-4.3l1-10.6c0-0.1,0-0.2,0-0.3
	H5.3z'
        />
        <path
          fill={colorHighlight}
          d='M12.2,20.1h-0.4c-0.3,0-0.6-0.4-0.6-0.9V9.5c0-0.5,0.2-0.9,0.6-0.9h0.4c0.3,0,0.6,0.4,0.6,0.9v9.7
	C12.8,19.7,12.5,20.1,12.2,20.1z'
        />
        <path
          fill={colorHighlight}
          d='M14.9,20.1l-0.4,0c-0.3,0-0.5-0.4-0.5-0.9l0.5-9.7c0-0.5,0.3-0.9,0.6-0.9l0.4,0C15.8,8.6,16,9,16,9.5l-0.5,9.7
	C15.5,19.7,15.2,20.1,14.9,20.1z'
        />
        <path
          fill={colorHighlight}
          d='M9.1,20.1l0.4,0c0.3,0,0.5-0.4,0.5-0.9L9.5,9.5c0-0.5-0.3-0.9-0.6-0.9l-0.4,0C8.2,8.6,8,9,8,9.5l0.5,9.7
	C8.5,19.7,8.8,20.1,9.1,20.1z'
        />
        <path
          fill={color}
          d='M21.7,4.9C21.7,4.9,21.7,4.9,21.7,4.9l0-0.7c0-0.5-0.5-1-1.1-1h-5.8V2.9c0-0.5-0.5-1-1.1-1h-3.4c-0.6,0-1.1,0.4-1.1,1v0.3
	H3.4c-0.6,0-1.1,0.4-1.1,1v0.7c0,0,0,0,0,0.1H21.7z'
        />
      </svg>
    </>
  );
};

export default TrashIcon;
