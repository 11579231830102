import React, { useState, useEffect } from "react";
import styled from 'styled-components/macro';
import _ from 'lodash';
import { RequestT } from '../types';
import {TableColT} from "@subApps/requests/utils";
import {LoadingContainer} from "@subApps/requests/PgRequests";
import MenuButton from "@as_core/elements/MenuButton";
import {FlexItem} from "@as_core/elements/flexStyles";
import BackArrowIcon from "@components/icons/backArrow.icon";
import {CircularProgress} from "@mui/material";
import useAssayRequests from "@subApps/requests/useAssayRequests";
import {useNavigate} from "react-router-dom";

const requestFields = [
  {
    label: 'Name',
    key: 'name',
    type: 'string',
  },
  {
    label: 'Request Type',
    key: 'request_type',
    type: 'string',
  },
  {
    label: 'Requester ID',
    key: 'user_id',
    type: 'string',
  },
  {
    label: 'Created',
    key: 'created_at',
    type: 'time',
  },
  { type: 'line'},
  {
    label: 'Status',
    key: 'status',
    type: 'string',
  },
  {
    label: 'Assigned To',
    key: 'assigned_to',
    type: 'string',
  },
  { type: 'line' },
  {
    label: 'Last Updated',
    key: 'updated_at',
    type: 'time',
  },
  {
    label: 'Resolved',
    key: 'resolved',
    type: 'boolean',
  },
];

const getValue = (request: RequestT, item: TableColT) => {
  if (item?.type === 'boolean') {
    return _.get(request, item.key, '').toString();
  } else if (item?.type === 'date') {
    const value = _.get(request, item.key, '');
    if (value === '') return '';
    const utc = new Date(value);
    return utc.toLocaleDateString() + ' ' + utc.toLocaleTimeString();
  }
  return _.get(request, item.key, '');
};

const displayValue = (request: RequestT, item: TableColT, index: number) => {
  if (item?.type === 'line') {
    return <HorizontalLine key={index} />;
  }
  return (
    <UserInformation key={index}>
      <Label>{item.label}:</Label>
      <Value>{getValue(request, item)}</Value>
    </UserInformation>
  );
};

type PropsT = {
  request_id: string;
}

const debug = false;
const ViewRequest = (props:PropsT) => {
  const { request_id } = props;
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [request, setRequest] = useState<RequestT|null>(null);
  const { getAssayRequest } = useAssayRequests();
  const navigate = useNavigate();
  debug && console.log('ViewBugRequest | request:', request_id);

  useEffect(() => {
    setIsLoaded(false);
    console.log('calling getAllAssayRequests');
    getAssayRequest(Number(request_id))
    .then((response) => {
      debug && console.log('response:', response);
      setRequest(response);
      setIsLoaded(true)
    })
    .catch((error) => {
       console.error(error);
    });
  }, [request_id]);

  debug && console.log('ViewRequest | request:', request);

  return (
    <ViewContainer>
      <FlexItem>
        <MenuButton
          text={'Go Back'}
          onClick={()=>navigate(-1)}
          icon={<BackArrowIcon size={32} />}
          width={220}
          height={40}
        />
      </FlexItem>
      <RequestContainer>
        { isLoaded ?
          Object.keys(request) ? (
            requestFields.map((item, index) => displayValue(request, item, index))
          ) : (
            <></>
          ) :
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        }
      </RequestContainer>
    </ViewContainer>
  );
};

export default ViewRequest;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background: ${(p) => p.theme.palette.backgroundSecondary};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(p) => p.theme.palette.accentPrimary};
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(p) => p.theme.palette.accentSecondary};
  }
`;

const RequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
`;

const HorizontalLine = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.palette.textSecondary};
`;

const UserInformation = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled.div`
  display: flex;
  width: 150px;
  margin-right: 5px;
  justify-content: flex-end;
  font-size: 14px;
  color: ${(p) => p.theme.palette.textSecondary};
`;

const Value = styled.div`
  display: flex;
  width: 450px;
  font-size: 14px;
`;
