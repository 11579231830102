import styled from "styled-components/macro";
// @ts-ignore
import spinner_img from '@as_core/elements/spinner.png';

type PropsT = {
  width?: number;
}

const Spinner = (props:PropsT) => {
  const { width=24 } = props;
  return (
    <SpinnerContainer width={width}>
      <img
        src={spinner_img}
        style={{ width: width, margin: 'auto', display: 'block' }}
        alt='Loading...'
      />
    </SpinnerContainer>
  );
};

export default Spinner;

const SpinnerContainer = styled.div<{width: number}>`
  display: flex;
  width: ${(p) => p.width}px;
  height: ${(p) => p.width}px;
  animation: rotating 2s linear infinite;
  @keyframes rotating {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  }
`;
