import React, {useEffect, useState, useContext} from "react";
import styled from "styled-components/macro";
import MenuButton from "../elements/MenuButton";
import PageTitle from "./PageTitle";
import UserProfileMenu from "./UserProfileMenu";
import { ThemeSwitcher } from "../elements/ThemeSwitcher";
import IconBugs from "../icons/IconBugs";
import { BugsModal } from '../bugsReporting/bugs.modal';
import { UserContext } from '../contexts/UserContext';

interface PropsT {
  title: string;
  appType?: string;
}

const debug = false;

const TopMenu = (props: PropsT) => {
  const { title, appType = ''} = props;
  const { user} = useContext(UserContext);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  // set user states
  useEffect(()=>{
    setIsAuthorized(user?.isAppAuthorized);
  }, [user]);
  debug && console.log('TopMenu | user: ', user, ' isAuthorized:', isAuthorized);

  const [isBugsModalOpen, setIsBugsModalOpen] = useState(false);

  const handleBugs = () => {
    setIsBugsModalOpen((previous) => !previous)
    console.log("handleBugs");
  };

  return (
    <>
      <Header>
        <HeaderLeft>
          <PageTitle title={title} appInfo={appType}/>
        </HeaderLeft>
        <HeaderCenter>
          <HeaderAccountDetails></HeaderAccountDetails>
        </HeaderCenter>
        <HeaderRight>
          <ItemContainer>
            <ThemeSwitcher />
          </ItemContainer>
          { (isAuthorized) ?
              <>
                <ItemContainer>
                  <MenuButton
                      icon={<IconBugs size={24} />}
                      onClick={handleBugs}
                      text={"Submit a Bug Report"}
                      tooltipPlacement={"bottom"}
                  />
                </ItemContainer>
              </>
              :
              <></>
          }
          <ItemContainer>
            <UserProfileMenu user={user}/>
          </ItemContainer>
        </HeaderRight>
      </Header>
      <BugsModal
          modalOpen={isBugsModalOpen}
          setModalOpen={setIsBugsModalOpen}
      />
    </>
  );
};

export default TopMenu;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -1px;
  align-items: center;
  background-color: ${(p) => p.theme.palette.backgroundSecondary};
`;

const HeaderLeft = styled.div`
  min-width: 500px;
  width: 40%;
  display: flex;
  grid-auto-flow: column;
  justify-self: start;
`;

const HeaderCenter = styled.div`
  display: flex;
  width: 20%;
  justify-content: center;
`;

const HeaderRight = styled.div`
  display: flex;
  width: 40%;
  justify-content: end;
  padding-right: 20px;
`;

const HeaderAccountDetails = styled.div`
  font-size: 12px;
  white-space: nowrap;
  color: ${(p) => p.theme.palette.accentSecondary};
`;

const ItemContainer = styled.div`
  height: 50px;
  padding: 0;
  margin: 0;
  width: max-content;
  align-items: center;
  display: grid;
`;
