export enum RequestStatus {
  OPEN = "Open",
  SHIPPED = "Shipped",
  RECEIVED = "Received",
  RUNNING = "Running",
  LOADING = "Loading",
  QC_CHECK = "QC Check",
  DELIVERED = "Delivered",
  ARCHIVED = "Archived"
}

export enum RequestType {
  BIOMIMETIC = "Biomimetic",
  CELLHEALTH = "Cell Health",
  ZEBRAFISH = "Zebrafish",
  ZEBRAFISH_CONSULT = "Zebrafish Consulting",
  KLARA_CONSULT = "Klara Consulting",
  QC_SOLUBILITY = "QC Solubility"
}

export type RequestT = {
  id: string;
  name: string;
  user_id?: string;
  org_id?: string;
  request_type?: RequestType;
  cat_code?: string;
  prod_name?: string;
  quantity?: number;
  unit_value?: string;
  assigned_to?: string;
  status?: RequestStatus;
}

export interface RequestsParamsT {
}

export interface ResponseT {
  errors: Array<string>;
  data: Array<any>;
}