import styled from 'styled-components/macro';

const PanelTitle = (props) => {
  return(
    <Title>
      {props.children}
    </Title>
  )
}
export default PanelTitle;

const Title = styled.div`
  color: ${(p) => p.theme.palette.titlePrimary};
  font-size: 18px;
  font-weight: ${(p) => p.theme.weights.heavy};
  padding-left: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
`;