import IconHome from '@components/icons/IconHome';
import FulfillmentActiveIcon from "@as_core/icons/fulfillmentActive.icon";
import FulfillmentCompleteIcon from "@as_core/icons/fulfillmentComplete.icon";
import FulfillmentComingIcon from "@as_core/icons/fulfillmentComing.icon";
import FulfillmentUnassignedIcon from "@as_core/icons/fulfillmentUnassigned.icon";

export const menuItems = [
  {
    id: 'home',
    name: 'Home',
    label: 'HM',
    pathname: '/config',
    icon: <IconHome />,
    authorizedOnly: false,
  },
  {
    id: 'reqOpen',
    name: 'Open',
    pathname: '/requests/open',
    icon: <FulfillmentComingIcon />,
    subApp: ['requests']
  },
  {
    id: 'reqShipped',
    name: 'Customer Shipped',
    pathname: '/requests/shipped',
    icon: <FulfillmentUnassignedIcon />,
    subApp: ['requests']
  },
  {
    id: 'reqActive',
    name: 'Active',
    pathname: '/requests/active',
    icon: <FulfillmentActiveIcon />,
    subApp: ['requests']
  },
  {
    id: 'reqComplete',
    name: 'Complete',
    pathname: '/requests/complete',
    icon: <FulfillmentCompleteIcon />,
    subApp: ['requests']
  },
];
