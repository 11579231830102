import { AxiosRequestConfig } from 'axios';
import { UserRegistration } from './api/';
import { handleError } from '@utils/misc';

export interface UserRegistrationT {
  name: string;
  email: string;
  orgId: string;
  roles: string[];
}

export interface ResponseT {
  errors: Array<string>;
  data: Array<any>;
}

const useUserRegistrationInfo = () => {

  const createUserRegistrationInfo = async (token, userInfo): Promise<ResponseT> => {
    let resp;

    try {
      resp = await UserRegistration.create(token, userInfo);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('createUserRegistration ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  const getUserRegistrationInfo = async (token, params): Promise<ResponseT> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await UserRegistration.get(token, config);
    } catch (err) {
      handleError(err);
      throw new Error('getUserRegistration Error' + err);
    }

    if (resp.data.errors.length > 0) {
      console.error('getUserRegistration ERRORS', resp.data.errors);
      throw new Error('getUserRegistration Error - data length is zero');
    }
    return resp.data;
  };

  const deleteUserRegistrationInfo = async (token, params): Promise<ResponseT> => {
    const config: AxiosRequestConfig = { params };
    let resp;

    try {
      resp = await UserRegistration.delete(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('deleteUser ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  const updateUserRegistrationInfo = async (token, userinfo): Promise<ResponseT> => {
    const config: AxiosRequestConfig = userinfo;
    let resp;

    try {
      resp = await UserRegistration.update(token, config);
    } catch (err) {
      handleError(err);
      return null;
    }

    if (resp.data.errors.length > 0) {
      console.log('getUser ERRORS', resp.data.errors);
    }
    return resp.data;
  };

  return {
    getUserRegistrationInfo,
    createUserRegistrationInfo,
    updateUserRegistrationInfo,
    deleteUserRegistrationInfo,
  };
};
export default useUserRegistrationInfo;
