import React from 'react';
import styled from 'styled-components/macro';

interface HeaderMenuPropsT {
  label: string,
  onClick?: (e) => void,
  isActive: boolean,
  fontSize?: number,
  width?: number,
  height?: number,
}

const HeaderMenu = (props: HeaderMenuPropsT) => {

  return (
    <MenuButtonContainer
        width={props.width}
        height={props.height}
        onClick={props.onClick}
        isActive={props.isActive}
    >
        <LabelContainer fontSize={props.fontSize}>{ props.label }</LabelContainer>
    </MenuButtonContainer>
  );
}

HeaderMenu.defaultProps = {
  isActive: false,
  fontSize: 14,
  height: 32
}

export default React.memo(HeaderMenu);

const MenuButtonContainer = styled.div<
  {height: number, width: number, isActive?: boolean}>`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: ${(p) => p.height}px;
  color: ${(p) => p.isActive ? 
          p.theme.palette.textPrimary : p.theme.palette.textSecondary };
  border-bottom: 2px solid ${(p) => p.isActive ? 
          p.theme.palette.accentPrimary : p.theme.palette.backgroundQuaternary};
  :hover {
    cursor: ${(p) => 'pointer' };
    color: ${(p) => p.theme.palette.accentSecondary };
  }
`;

const LabelContainer = styled.div<{fontSize: number}>`
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  font-size: ${(p)=>p.fontSize}px;
  white-space: nowrap;
`;
