import styled from 'styled-components/macro';
import { FlexItem } from '@as_core/elements/flexStyles';
import { RequestT } from '../types';
import { getTitleInfo } from '../utils';

type PropsT = {
  action: string,
  request: RequestT
}

const ReportInfoHeader = (props:PropsT) => {
  return(
    <InfoHeaderContainer>
      <FlexItem>{ getTitleInfo(props.action, props.request) }</FlexItem>
    </InfoHeaderContainer>
  )
}
export default ReportInfoHeader;

const InfoHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
`;
