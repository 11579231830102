import React from 'react';
import styled from 'styled-components';
import TileCard from '@components/layout/TileCard';

import {TileCardT} from "@components/layout/TileCard.type";

interface propsT {
  isAuthorized: boolean;
  cards: Array<TileCardT>;
}

const debug = false;

// display a set of active/inactive tiles
const PageTiles = (props: propsT) => {
  debug && console.log('PageTiles | props: ', props);
  const {isAuthorized, cards} = props;

  return (
      <TileContainer>
        <Cards>
          {cards.map((card, cardId) => {
            return(
              <TileCard
                key = {cardId}
                isAuthorized={isAuthorized}
                card = {card}
              />
            );
          })}
        </Cards>
      </TileContainer>
  );
};

const TileContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export default PageTiles;
