import React from 'react';
import styled from 'styled-components/macro';
import { Tooltip } from '@mui/material';

const MenuTooltip = (props) => {
  const { text, tooltipPlacement = 'right'} = props;
  return (
    <StyledTooltip
      title={text}
      placement={tooltipPlacement}
      arrow
    >
      { props.children }
    </StyledTooltip>
  );
}
export default MenuTooltip;

const StyledTooltip = styled(Tooltip)`
`;

