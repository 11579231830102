// styles.js (or styles.ts)
import styled from 'styled-components';
import { TextField, Button } from '@mui/material';

export const PageCenter = styled.div`
  display: flex;
  width: calc(100vw - 50px);
  height: calc(100vh - 50px);
  justify-items: center;
  align-content: center;
`

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  max-width: 800px;
  margin: auto;
  border: 1px solid white;
  border-radius: 30px;
  padding: 2rem;
  background: ${(props) => props.theme.palette.backgroundSecondary};
`;

export const StyledTextField = styled(TextField)`
  height: 50px;
`;

export const StyledButton = styled(Button)`
  border-radius: 30px !important;
  height: 45px;
  width: 150px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
    width: 98.5%;
    margin-left: 5px;
`;