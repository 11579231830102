import React from 'react';

const IconSciscore = ({ size = 24 }) => {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.55809 3.60091L7.64666 3.61268C8.10854 3.71539 8.56309 3.86945 8.99564 4.06754C9.0501 4.09269 9.09918 4.12563 9.14201 4.16464L9.14262 6.98283L8.19251 8.67406C7.76677 8.09506 7.07719 7.7201 6.3 7.7201C5.00917 7.7201 3.96 8.7603 3.96 10.0401C3.96 11.2605 4.91398 12.263 6.12179 12.3534L4.11637 15.9172L4.03825 16.0679C3.93 16.0249 3.82286 15.979 3.71703 15.9306C3.52641 15.8425 3.40178 15.6591 3.37978 15.4537L3.29914 14.5587C3.21116 14.5 3.12318 14.4339 3.04254 14.3679L2.19942 14.5587C1.99414 14.61 1.7742 14.544 1.6349 14.3826C1.31232 14.0304 1.03373 13.6343 0.799124 13.2161C0.703816 13.0327 0.703816 12.8126 0.806455 12.6365L1.25367 11.8662C1.21701 11.7635 1.18036 11.6534 1.15103 11.5434L0.344576 11.1912C0.139297 11.0958 0 10.8831 0 10.6557V9.3351C0 9.10034 0.131965 8.89492 0.344576 8.79954L1.15103 8.44739C1.18036 8.33735 1.21701 8.2273 1.25367 8.12459L0.806455 7.35426C0.703816 7.17819 0.696484 6.9581 0.799124 6.78202C1.03373 6.35651 1.31232 5.96768 1.6349 5.60819C1.7742 5.45413 1.99414 5.38076 2.19942 5.43212L3.04254 5.6302C3.12318 5.56417 3.21116 5.49814 3.29914 5.43945L3.37978 4.54441C3.40178 4.33899 3.52641 4.15558 3.71703 4.06754C4.14958 3.86212 4.60413 3.71539 5.06601 3.61268C5.27129 3.56866 5.4839 3.64202 5.62319 3.80343L6.21704 4.49305H6.49563L7.08948 3.80343C7.18374 3.68814 7.32288 3.61777 7.46951 3.60301L7.55809 3.60091Z'
          fill='url(#paint0_radial_692_15498)'
          fillOpacity='0.5'
        />
        <path
          d='M13.6033 -0.0400391C14.317 -0.0400391 14.9034 0.484046 14.9034 1.13291C14.9034 1.64202 14.5383 2.08126 14.0348 2.24098V6.41024C14.0348 6.78437 14.1398 7.15099 14.3377 7.46846L19.6058 15.9171C20.1369 16.7756 20.1313 17.7988 19.5892 18.6423C19.036 19.4909 18.0568 20 16.9669 20H5.04493C3.95508 20 2.97588 19.4909 2.42265 18.6423C1.86943 17.7938 1.85837 16.7756 2.39499 15.9171L7.66306 7.46846C7.86102 7.15099 7.96596 6.78437 7.96596 6.41024V2.24098C7.46252 2.07626 7.09739 1.64202 7.09739 1.13291C7.09739 0.489037 7.67828 -0.0400391 8.39747 -0.0400391H13.6033ZM11 1.49996C10.1716 1.49996 9.5 2.17153 9.5 2.99996V7.39441C9.5 7.78926 9.38312 8.17527 9.1641 8.50381L5.57293 13.8906C5.35391 14.2191 5.23703 14.6051 5.23703 15C5.23703 16.1045 6.13246 17 7.23703 17H14.763C15.1578 17 15.5438 16.8831 15.8724 16.6641C16.7914 16.0514 17.0398 14.8096 16.4271 13.8906L12.8359 8.50381C12.6169 8.17527 12.5 7.78926 12.5 7.39441V2.99996C12.5 2.17153 11.8284 1.49996 11 1.49996Z'
          fill='white'
        />
        <path
          d='M2.39499 16.3806C1.85837 17.2391 1.86943 18.2573 2.42265 19.1058C2.97588 19.9544 3.95508 20.4635 5.04493 20.4635H16.9669C18.0568 20.4635 19.036 19.9544 19.5892 19.1058C20.1313 18.2623 20.1369 17.2391 19.6058 16.3806C18.8072 15.0999 16.432 11.2903 15.9629 10.5379C15.8262 10.3187 13.9605 9.59556 12.33 10.3035C10.9328 10.9101 11.1051 12.4074 8.64 12.9548C6.17491 13.5021 4.94036 12.4246 4.90147 12.3622C4.35677 13.2358 3.4844 14.6335 2.39499 16.3806Z'
          fill='url(#paint1_linear_692_15498)'
        />
        <defs>
          <radialGradient
            id='paint0_radial_692_15498'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(-3.14 2.94557) rotate(90) scale(9.14262 17.0025)'
          >
            <stop stopColor='#83F6C9' />
            <stop offset='1' stopColor='#4CADE9' />
          </radialGradient>
          <linearGradient
            id='paint1_linear_692_15498'
            x1='11'
            y1='10'
            x2='11'
            y2='20.4635'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#83F6C9' />
            <stop offset='1' stopColor='#4CADE9' />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default IconSciscore;
