import styled from 'styled-components/macro';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export interface SingleSelectOptionT {
  value: string;
  label: string;
}

export interface SingleSelectPropsT {
  id: string;
  label: string;
  value: string;
  options: SingleSelectOptionT[];
  onChange: any;
  width?: number;
  keepOpen?: boolean;
  error?: string;
}

const debug = false;
const SingleSelect = (props: SingleSelectPropsT) => {
  debug &&
    console.log(
      'SingleSelect | value',
      props?.value,
      'options',
      props?.options
    );
  let width = props?.width ? props.width : 0;

  if (props.keepOpen) {
    return (
        <StyledSelect
            id={props.id}
            variant={'filled'}
            label={props.label}
            value={props.value}
            open={true}
            disableUnderline
            onChange={props.onChange}
            width={width}
        >
          <MenuItem value=''>-</MenuItem>
          {props?.options.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
          ))}
        </StyledSelect>
    );
  }
  return (
      <StyledSelect
          id={props.id}
          variant={'filled'}
          label={props.label}
          value={props.value}
          disableUnderline
          onChange={props.onChange}
          width={width}
      >
        <MenuItem value=''>-</MenuItem>
        {props?.options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
        ))}
      </StyledSelect>
  );
};

export default SingleSelect;

const StyledSelect = styled(Select)<{ width: number; error?: boolean }>`
  display: flex;
  width: ${(p) => (p.width ? p.width + 'px' : '100%')};


  & .MuiFilledInput-input.MuiSelect-select { // font size and border
    font-size: 15px;
    padding-left: 10px;
    border-radius: 5px;
    border: 1px solid ${(p) => p.theme.palette.accentSecondary};
  }
  
  & .MuiInputBase-root {
    padding-left: 10px;
    font-size: 18px;
    color: blue; !important;
    border: 1px solid ${(p) => p.theme.palette.accentSecondary};
    border-radius: 15px;
    
  }
  & .MuiSelect-select {
    padding: 0;
    font-size: 14px;
  }
  & .hover {
    border: 0;
  }
  & .MuiSelect-select:before {
    border: 0;
  }
  & .MuiFilledInput-input.MuiSelect-select {
    color: ${(p) => p.theme.palette.textSecondary};
  }
`;
