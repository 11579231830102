import {RequestT, RequestStatus} from './types';
import axios from 'axios';
import { AssayRequests } from '@utils/api';
import { handleError } from '@utils/misc';
import useCognito from "@as_core/account/useCognito";

axios.interceptors.request.use(request => {
  console.log('Starting Request', JSON.stringify(request, null, 2))
  return request
})

axios.interceptors.response.use(response => {
  console.log('Response:', JSON.stringify(response, null, 2))
  return response
})

function actionToStatus(action:string) {
  if (action === 'open') {
    return RequestStatus.OPEN
  } else if (action === 'shipped') {
    return RequestStatus.SHIPPED
  }
}

const useAssayRequests = () => {
  const { getToken } = useCognito();

  const getAssayRequest = async (id: number): Promise<RequestT> => {
    const token = getToken();
    let resp;
    // TODO - handle errors from the API

    try {
      resp = await AssayRequests.get(token, id);
    } catch (err) {
      handleError(err);
      return null;
    }
    console.log('resp:', resp);
    return resp.data;
  };

  const getAllAssayRequests = async (): Promise<RequestT[]> => {
    const token = getToken();
    let resp;
    // TODO - handle errors from the API

    try {
      resp = await AssayRequests.getAll(token);
    } catch (err) {
      handleError(err);
      return null;
    }
    console.log('resp:', resp);
    return resp.data;
  };

  const getAssayRequestsByStatus = async (status:string): Promise<RequestT[]> => {
    const token = getToken();
    let resp;
    // TODO - handle errors from the API

    try {
      resp = await AssayRequests.getByStatus(token, actionToStatus(status));
    } catch (err) {
      handleError(err);
      return null;
    }
    console.log('resp:', resp);
    return resp.data;
  };

  const updateAssayRequest = async (id: string, payload: any): Promise<RequestT> => {
    const token = getToken();
    let resp;
    // TODO - handle errors from the API

    try {
      resp = await AssayRequests.update(token, id, payload);
    } catch (err) {
      handleError(err);
      return null;
    }
    console.log('resp:', resp);
    return resp.data;
  };

  return {
    getAssayRequest,
    getAllAssayRequests,
    getAssayRequestsByStatus,
    updateAssayRequest
  };
}

export default useAssayRequests;