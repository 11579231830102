import styled from 'styled-components/macro';
import {APP_CONFIG} from "@app_config/app";

const PageTitle = ({title, appInfo}) => {
  return(
    <Title>
      <AppTitle>{APP_CONFIG.name}</AppTitle>
      { appInfo !== '' ?
      <AppType>{appInfo}</AppType>
        : null
      }
      <AppTitle>:</AppTitle>
      <SubTitle>{title}</SubTitle>
    </Title>
  )
}
export default PageTitle;

const Title = styled.div`
  display: flex;
  padding-left: 5px;
  vertical-align: center;
  color: ${(p) => p.theme.palette.textPrimary};
  font-size: ${(p) => p.theme.sizes.default};
`;

const AppTitle = styled(Title)`
  padding-left: 0;
  color: ${(p) => p.theme.palette.accentPrimary};
`;

const AppType = styled(AppTitle)`
  font-size: 12px;
  padding: 0 3px;
  color: yellow;
`;

const SubTitle = styled(Title)`
  padding-left: 4px;
`;