import { Tooltip } from '@mui/material';
import { truncateString } from '@utils/misc';
import React from 'react';
import { RequestT } from './types';
import _ from 'lodash';
import HoverInfoText from '@as_core/account/fields/HoverInfoText';
import MenuButton from '@as_core/elements/MenuButton';
import EditIcon from '@as_core/icons/edit.icon';
import ViewIcon from '@as_core/icons/view.icon';
import TextButton from "@as_core/controls/buttons/TextButton";
import HoverTextTruncate from "@as_core/elements/HoverTextTruncate";

export type TableColT = {
  value?: string,
  label?: string,
  key?: string,
  type?: string,
  width?: number
}

export function updateByDot(obj, key, value) {
  const parts = key.split('.')
  let obj_ref = obj;
  while (parts.length) {
    const part = parts.shift();
    if (!parts.length) {
      obj_ref[part] = value;
    } else {
      obj_ref = obj_ref[part] = obj_ref[part] || {};
    }
  }
}

export const VENDOR_MASK:{[key: string]: string} = {
  'AS_V_0001': 'AsedaSciences',
  'AS_V_0002': 'Tanguay Laboratories',
  'AS_V_0003': 'Klara Valko',
  'AS_V_0004': 'IndigoBridge',
}

export const ColDef: {[key:string]: TableColT[]} = {
  'open': [
    { value: 'view', label: '', type: 'view', width: 40 },
    { value: 'edit', label: '', type: 'edit', width: 40 },
    { value: 'status', label: 'Status', type: 'status', width: 120 },
    { value: 'name', label: 'Name', type: 'hover_text', width: 180},
    { value: 'created_at', label: 'Created', type: 'date', width: 70 },
    { value: 'user_id', label: 'Customer', type: 'hover_info', width: 70 },
    { value: 'quantity', label: 'Quantity', type: 'quantity', width: 100 },
    { value: 'prod_name', label: 'Request', type: 'prod_name', width: 180 },
    { value: 'assigned_to', label: 'Assigned To', type: 'vendor', width: 150 },
    { value: 'updated_at', label: 'Updated', type: 'date', width: 70 },
  ],
  'unassigned': [
    { value: 'view', label: '', type: 'computed', width: 40 },
    { value: 'edit', label: '', type: 'computed', width: 40 },
    { value: 'comment', label: '', type: 'computed', width: 40 },
    { value: 'status', label: 'Status', type: 'computed', width: 100 },
    { value: 'title', label: 'Title', type: 'string', width: 200 },
    { value: 'category', label: 'Category', type: 'string', width: 200 },
    { value: 'bug_reference', label: 'Ref', type: 'string', width: 80 },
    { value: 'submit.submitter.email', label: 'Submitter', type: 'string', width: 200 },
    { value: 'submit.time_submitted', label: 'Sub\'d', type: 'date', width: 80 },
    { value: 'submit.time_submitted', label: 'Ack\'d', type: 'date', width: 80 },
  ],
  'active': [
    { value: 'view', label: '', key: '', type: 'computed', width: 40 },
    { value: 'edit', label: '', key: '', type: 'computed', width: 40 },
    { value: 'comment', label: '', key: '', type: 'computed', width: 40 },
    { value: 'status', label: 'Status', key: '', type: 'computed', width: 55 },
    { value: 'title', label: 'Title', key: 'title', type: 'string', width: 200 },
    { value: 'bug_reference', label: 'Ref', key: 'bug_reference', type: 'string', width: 75 },
    { value: 'assigned_to', label: 'Assigned To', key: 'resolution.assigned', type: 'string', width: 240 },
    { value: 'submitter', label: 'Submitter', key: 'submit.submitter.email', type: 'string', width: 200 },
    { value: 'submitted', label: 'Sub\'d', key: 'submit.time_submitted', type: 'date', width: 80 },
    { value: 'acknowledged', label: 'Ack\'d', key: 'submit.time_acknowledged', type: 'date', width: 80 },
    { value: 'assigned', label: 'Assigned', key: 'resolution.time_assigned', type: 'date', width: 80 },
  ],
  'closed': [
    { value: 'view', label: '', key: '', type: 'computed', width: 40 },
    { value: 'edit', label: '', key: '', type: 'computed', width: 40 },
    { value: 'comment', label: '', key: '', type: 'computed', width: 40 },
    { value: 'status', label: 'Status', key: '', type: 'computed', width: 55 },
    { value: 'title', label: 'Title', key: 'title', type: 'string', width: 200 },
    { value: 'bug_reference', label: 'Ref', key: 'bug_reference', type: 'string', width: 75 },
    { value: 'assigned_to', label: 'Assigned To', key: 'resolution.assigned', type: 'string', width: 240 },
    { value: 'submitter', label: 'Submitter', key: 'submit.submitter.email', type: 'string', width: 200 },
    { value: 'submitted', label: 'Sub\'d', key: 'submit.time_submitted', type: 'date', width: 80 },
    { value: 'resolved', label: 'Resolved', key: 'resolution.time_resolved', type: 'date', width: 80 },
  ]
};


export const getRequestStatus = (
  request: RequestT,
  handleClick: (a:string, id:string) => void
) => {
  const status = request?.status;
  if (status === 'Open') {
    const assigned_to = _.get(request, 'assigned_to', '');
    // console.log('status: ', status, 'assigned_to', assigned_to);
    if (assigned_to === null || assigned_to === '') {
      return (
        <TextButton
          width={135}
          height={30}
          fontSize={12}
          margin={'2px'}
          label={'Assign Request'}
          onClick={() => handleClick('edit', request?.id)}
        />
      );
    } else {
      return "Waiting on Customer";
    }
  }
  return status;
};

export const getStringHover = (text: string, width: number) => {
  if (typeof(text) !== 'string') return '';
  const characters = Math.floor(width/4);
  if (text.length < characters) return text;
  return(
    <Tooltip title={text}>
      { truncateString(text, characters)}
    </Tooltip>
  )
}

export const getTitleInfo = (action: string, request: RequestT) => {
  return(
    <>
      {_.get(request, 'name', '') + ' ' }
      <HoverInfoText text={_.get(request, 'description', '')}/>
    </>
  )
}


// Return the display value for the column from the AssayRequest
export const getDisplayValue = (
  action: string,
  col: TableColT,
  request:RequestT,
  handleClick: (a:string, id:string) => void
) => {
  if (col.type === 'status' ) {
    return getRequestStatus(request, handleClick);
  } else if (col.type === 'title') {
    return(getTitleInfo(action, request));
  } else if (col.type === 'edit') {
    if (action === 'open') {
      if (_.get(request, 'is_assigned', '') === '') {
        return (
          <MenuButton
            onClick={() => handleClick('edit', request?.id)}
            icon={<EditIcon/>}
            text={'Adjust Assignment'}
            tooltipPlacement={'bottom'}
          />
        )
      } else {
        return <></>
      }
    } else {
      return (
        <MenuButton
          onClick={() => handleClick('edit', request?.id)}
          icon={<EditIcon/>}
          text={'Update Assay Request'}
          tooltipPlacement={'bottom'}
        />
      )
    }
  } else if (col.type === 'hover_info') {
    const value = _.get(request, col.value, '');
    return (
      <HoverInfoText text={value}/>
    )
  } else if (col.type === 'hover_text') {
    const value = _.get(request, col.value, '');
    return (
      <HoverTextTruncate text={value}/>
    )
  } else if (col.type === 'vendor') {
    const value = _.get(request, 'assigned_to', '');
    if (Object.hasOwn(VENDOR_MASK, value)) return VENDOR_MASK[value];
    return value;
  } else if (col.type === 'view') {
    return(
      <MenuButton
        onClick={() => handleClick('view', request?.id)}
        icon={<ViewIcon />}
        text={'View Full Assay Request'}
        tooltipPlacement={'bottom'}
      />
    )
  } else if (col.type === 'quantity') {
    let quantity = _.get(request, 'quantity', 0);
    let unit_size = _.get(request, 'unit_value', '');
    if (unit_size === null || quantity === null) return '';
    const parts = unit_size.split(' ');
    if (parts.length > 1) {
      quantity = quantity * Number(parts[0]);
      unit_size = parts[1];
      if (quantity > 1) unit_size += 's';
    }
    return quantity.toString().concat(' ', unit_size);
  } else {
    const value = _.get(request, col.value, '');
    if (col.type === 'time') {
      const utcDate = new Date(value)
      return utcDate.toLocaleDateString() + ' ' + utcDate.toLocaleTimeString();
    } else if (col.type === 'date') {
      const utcDate = new Date(value)
      return utcDate.toLocaleDateString();
    } else if (col.type === 'number') {
      return value.toString();
    } else {
      return getStringHover(value, col.width);
    }
  }
}


export type TableFieldsT = {
  value: string;
  label: string;
  type?: string;
  width?: number;
}


export const getRequestsTableRows = (
  action: string,
  assayRequests: RequestT[],
  cols: TableColT[],
  handleEditClick: (t:string, u:string)=>void) => {
  let rows = [];
  for (const request of assayRequests) {
    let row = {};
    for (const col of cols) {
      row[col.value] = getDisplayValue(action, col, request, handleEditClick);
    }
    rows.push(row);
  }
  return rows;
};

export const getTitle = (action: string): string => {
  switch(action) {
    case 'open':
      return 'Upcoming Assay Requests';
    case 'shipped':
      return 'Compounds Shipped';
    case 'active':
      return 'Active Assay Requests';
    case 'complete':
      return 'Completed Assay Requests';
  }
  return 'Title';
}


export const getRequestStatusByAction = (action: string): string => {
  switch(action) {
    case 'open':
      return 'Open';
    case 'shipped':
      return 'Shipped';
    case 'active':
      return 'in_process';
    case 'closed':
      return 'closed';
  }
  return 'Title';
}