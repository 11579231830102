import {useEffect, useState} from "react";
import styled from 'styled-components/macro';
import {FlexBanner, FlexBannerGroup} from '@as_core/elements/flexStyles';
import {UserT} from "@as_core/contexts/UserContext";

type PropsT = {
  user: UserT,
  userAuthorizations: string[]
}

const ReportPageHeader = (props:PropsT) => {
  const {userAuthorizations} = props;
  const [userAuthText, setUserAuthText] = useState<string>("");
  useEffect(() => {
    const newUserAuthText = userAuthorizations.join(', ');
    setUserAuthText('Authorizations: ' + newUserAuthText);
  }, [userAuthorizations]);

  return(
    <StyledFlexBanner height={40} border>
      <FlexBannerGroup></FlexBannerGroup>
      <MidBanner>{userAuthText}</MidBanner>
      <FlexBannerGroup></FlexBannerGroup>
    </StyledFlexBanner>
  )
}
export default ReportPageHeader;

const StyledFlexBanner = styled(FlexBanner)`
  background: ${(p) => p.theme.palette.backgroundTertiary };
  margin-bottom: 5px;
`;

const MidBanner = styled(FlexBannerGroup)`
  font-size: 12px;
  font-style: italic;
  color: ${(p) => p.theme.palette.textSecondary};
`;
