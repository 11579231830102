import { auth3RnDClient, authCoreClient, authServicesClient } from './base';

export const ApiInfo = {
  get: (token) => auth3RnDClient(token).get('/info/'),
};

export const User = {
  get: (token, config) => auth3RnDClient(token).get('/user', config),
  check: (token, config) => auth3RnDClient(token).get('/user/check', config),
  create: (token, data) => auth3RnDClient(token).post('/user/create', data),
  update: (token, data) => auth3RnDClient(token).put('/user/update', data),
  delete: (token, data) => auth3RnDClient(token).delete('/user/delete', data),
};


// AsedaSciences Core Services
export const ApiCoreInfo = {
  get: (token) => authCoreClient(token).get('/info/'),
};

// AsedaSciences Core Services
export const UserRegistration = {
  get: (token, config) => authCoreClient(token).get('/user/', config),
  create: (token, data) => authCoreClient(token).post('/user/create', data),
  update: (token, data) => authCoreClient(token).post('/user/update', data),
  delete: (token, data) => authCoreClient(token).post('/user/delete', data),
};

// AsedaSciences Core Services
export const BugReports = {
  get: (token, config) => authCoreClient(token).get('/bugs/', config),
  submit: (token, data) => authCoreClient(token).post('/bugs/submit', data),
  update: (token, data) => authCoreClient(token).put('/bugs/update', data),
  delete: (token, id) => authCoreClient(token).post('/bugs/delete', id),
};

// AsedaSciences Request Services
export const AssayRequests = {
  getAll: (token) => authServicesClient(token).get('/requests'),
  getByStatus: (token, status) => authServicesClient(token).get(`/requests?status=${status}`),
  get: (token, requestId) => authServicesClient(token).get(`/requests/${requestId}`),
  create: (token, data) => authServicesClient(token).post('/requests', data),
  update: (token, requestId: string, data) => authServicesClient(token).patch(`/requests/${requestId}`, data),
  delete: (token, requestId: string) => authServicesClient(token).delete(`/requests/${requestId}`),
};
