import React from 'react';
import styled from 'styled-components/macro';

interface TextButtonPropsT {
  label: string,
  icon?: any,
  onClick?: (e) => void,
  isActive?: boolean,
  isDisabled?: boolean,
  isWarning?: boolean,
  inProcess?: boolean,
  iconRight?: boolean,
  margin?: string,
  fontSize?: number,
  width?: number,
  height?: number,
}

const TextButtonBasic = (props: TextButtonPropsT) => {
  const { label, icon, onClick, isActive = false, isWarning = false, iconRight = false,
          fontSize=14, margin="2px 4px", width=100, height=40 } = props;

  const handleClick = (e) => {
    if (!props.isDisabled) onClick(e);
  }

  const getButtonContents = () => {
    if (iconRight) {
      return(
        <>
          <LabelContainer fontSize={fontSize}>{ label }</LabelContainer>
          <IconContainer>{ icon }</IconContainer>
        </>
      )
    } else {
      return(
        <>
          <IconContainer>{ icon }</IconContainer>
          <LabelContainer fontSize={fontSize}>{ label }</LabelContainer>
        </>
      )
    }
  }

  return (
    <TextButtonContainer
        margin={margin}
        width={width}
        height={height}
        isWarning={isWarning}
        isDisabled={props.isDisabled}
        onClick={handleClick}
        className={isActive ? 'active' : ''}
    >
      <TextButtonContents isDisabled={props.isDisabled}>
        { getButtonContents() }
      </TextButtonContents>
    </TextButtonContainer>
  );
}

export default React.memo(TextButtonBasic);

const TextButtonContainer = styled.div<
  {margin: string, height: number, width: number,
  isWarning?: boolean, isDisabled?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(p) => p.margin};
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  opacity: 1.0;
  border-radius: 10px;
  border: 1px solid ${(p) => p.theme.palette.textSecondary };
  color: ${(p) => p.isWarning ? 
          'black' : p.theme.palette.backgroundPrimary };
  background-color: ${(p) => p.isWarning ? 
          'red' : p.isDisabled ? '#999999' : p.theme.palette.accentPrimary };
  :hover {
    cursor: ${(p) => p.isDisabled ? 'not-allowed' : 'pointer' };
    color: ${(p) => p.isWarning ? 'black' 
            : p.isDisabled ? p.theme.palette.backgroundPrimary : p.theme.palette.textPrimary };
    border: 1px solid ${(p) => p.theme.palette.textPrimary };
    background-color: ${(p) => p.isWarning ? 
            p.theme.palette.accentPrimary : p.isDisabled ? '#999999' 
            : p.theme.palette.backgroundQuaternary };
  }
`;
TextButtonContainer.defaultProps = {
  isWarning: false,
  isDisabled: false
}

const TextButtonContents = styled.div<{
  isWarning?: boolean, isDisabled?: boolean, inProcess?: boolean
}>`
  display: flex;
  :hover {
    cursor: ${(p) => p.isDisabled ? 'not-allowed' : 'pointer' };
  }
`;

TextButtonContents.defaultProps = {
  isWarning: false,
  isDisabled: false,
  inProcess: false
}

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.palette.textPrimary };
`;

const LabelContainer = styled.div<{fontSize: number}>`
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  font-size: ${(p)=>p.fontSize}px;
  white-space: nowrap;
`;
