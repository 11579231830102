import React, { useContext } from 'react';
import { StyleContext,THEME } from '@theme/AppStyles';
import MenuButton from './MenuButton';
import SwitchIcon from '../icons/IconSwitch';

export const ThemeSwitcher = () => {
  const [style, setStyle] = useContext(StyleContext);

  const toggleTheme = () => {
    console.log("toggleTheme ", style);
    if (style === THEME.LIGHT) {
      setStyle(THEME.DARK);
    } else {
      setStyle(THEME.LIGHT);
    }
  }

  return (
    <MenuButton
      onClick={toggleTheme}
      icon={<SwitchIcon />}
      spacing={'horizontal'}
      text={'Switch Dark/Light Mode'}
      tooltipPlacement={'bottom'}
    />
  );
}

